import type { ProductServicesHandlers } from './useProductServicesHandlers.types';
import type { ProductServicesData } from './useProductServicesData.types';

import ROUTES from 'admin/constants/routes';

import * as uiLib from '@compliance.ai/web-components';
import * as productServiceDataApi from 'shared/features/productServices/productServices.apiV2';
import * as errorUtils from 'shared/utils/errors';
import * as helpers from './useProductServicesHandlers.helpers';

import { useNavigate } from 'react-router-dom';

export const useProductServicesHandlers = ({
  localState,
  localActions
}: {
  localState: ProductServicesData['localState'];
  localActions: ProductServicesData['localActions'];
}): ProductServicesHandlers => {
  const navigate = useNavigate();
  const handleProductServicesFetch: ProductServicesHandlers['handleProductServicesFetch'] = async (
    params,
    options
  ) => {
    try {
      const productServicesResponse = await productServiceDataApi.fetchProductServicesData({
        ...params,
        ...localState.filters,
        [uiLib.TABLE_PARAMS.SORT]: helpers.getApiSortSettings(params[uiLib.TABLE_PARAMS.SORT])
      });

      return {
        results: productServicesResponse.items,
        count: productServicesResponse.count ?? 0
      };
    } catch (e) {
      errorUtils.logError(e);

      return {
        results: [],
        count: 0
      };
    }
  };

  const handleFiltersSubmit: ProductServicesHandlers['handleFiltersSubmit'] = filters => {
    localActions.setTableParams({
      ...localState.tableParams,
      [uiLib.TABLE_PARAMS.OFFSET]: 0
    });
    localActions.setFilters(filters);
    localActions.setReloadTrigger(reloadTrigger => !reloadTrigger);
  };

  const handleCreateNewProductService: ProductServicesHandlers['handleCreateNewProductService'] =
    () => {
      navigate(`${ROUTES.productServiceTool}/new`);
    };

  return {
    handleProductServicesFetch,
    handleFiltersSubmit,
    handleCreateNewProductService
  };
};
