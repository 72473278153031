import type { FC } from 'react';
import type { ProductServiceStatusProps } from './ProductServiceStatus.types';
import type { ProductServiceFromResponse } from 'shared/features/productServices/productServices.types';

import Badge from 'admin/components/Badge';
import { getProductServiceStatusBadgeInfo } from './ProductServiceStatus.helpers';

export const ProductServiceStatus: FC<ProductServiceStatusProps> = ({ row }) => {
  const conceptValue = row as ProductServiceFromResponse;
  const badgeInfo = getProductServiceStatusBadgeInfo(conceptValue?.is_active);
  return (
    <Badge type={badgeInfo.type} size="medium" title={badgeInfo.text}>
      {badgeInfo.text}
    </Badge>
  );
};
