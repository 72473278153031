import type { MetaConceptPageData } from './useMetaConceptPageData.types';
import type { MetaConceptEditHandlers } from './useMetaConceptPageHandlers.types';

import { useMetaConceptsReduxActions } from 'shared/features/metaConcepts/hooks';
import { useCallback } from 'react';

export const useMetaConceptPageHandlers = ({
  formattedData
}: {
  formattedData: MetaConceptPageData['formattedData'];
}): MetaConceptEditHandlers => {
  const metaConceptsReduxActions = useMetaConceptsReduxActions();

  const handleLoadMetaConcept: MetaConceptEditHandlers['handleLoadMetaConcept'] =
    useCallback(() => {
      metaConceptsReduxActions.clearMetaConcepts();
      if (!isNaN(Number(formattedData.metaConceptId))) {
        metaConceptsReduxActions.fetchMetaConcept({ id: formattedData.metaConceptId });
      }
    }, [formattedData.metaConceptId, metaConceptsReduxActions]);

  const handleUpdateMetaConceptStatus: MetaConceptEditHandlers['handleUpdateMetaConceptStatus'] =
    isActive => {
      metaConceptsReduxActions.updateMetaConcept({
        id: formattedData.metaConceptId,
        active: isActive
      });
    };

  return {
    handleLoadMetaConcept: handleLoadMetaConcept,
    handleUpdateMetaConceptStatus: handleUpdateMetaConceptStatus
  };
};
