import type { ProductServicePageStatusProps } from '../ProductServiceStatus.types';
import type { ProductServiceStatusData } from './useProductServiceStatusData.types';
import type { ProductServicePageStatusHandlers } from './useProductServiceStatusHandlers.types';

import ROUTES from 'admin/constants/routes';
import * as constants from '../ProductServiceStatus.constants';

import { useHistory } from 'admin/utils/hooks';
import { useLocation } from 'react-router-dom';

export const useProductServiceStatusHandlers = ({
  props,
  localActions
}: {
  props: Pick<ProductServicePageStatusProps, 'onChangeStatus'>;
  localActions: ProductServiceStatusData['localActions'];
}): ProductServicePageStatusHandlers => {
  const history = useHistory();
  const location = useLocation();

  const handleStatusDropdownToggle: ProductServicePageStatusHandlers['handleStatusDropdownToggle'] =
    isOpen => () => {
      localActions.setIsOpen(isOpen);
    };

  const handleListOptionClick: ProductServicePageStatusHandlers['handleListOptionClick'] = ([
    option
  ]) => {
    switch (option.value) {
      case constants.PRODUCT_SERVICE_CREATION_VALUES.ACTIVE: {
        props.onChangeStatus(true);
        break;
      }

      case constants.PRODUCT_SERVICE_CREATION_VALUES.DEACTIVATED: {
        props.onChangeStatus(false);
        break;
      }
    }

    localActions.setIsOpen(false);
  };

  const handleGoBack: ProductServicePageStatusHandlers['handleGoBack'] = () => {
    history.push(`${ROUTES.contentManagement}?tab=product_services`, location.state);
  };

  return {
    handleStatusDropdownToggle,
    handleListOptionClick,
    handleGoBack
  };
};
