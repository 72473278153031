import type { MetaConceptDetailsNormalized } from '../../MetaConceptDetails/MetaConceptDetails.types';
import type { MetaConceptStatusData } from './useMetaConceptStatusData.types';

import * as uiLib from '@compliance.ai/web-components';

import { useMemo, useState } from 'react';

export const useMetaConceptStatusData = ({
  metaConceptDetails
}: {
  metaConceptDetails: MetaConceptDetailsNormalized;
}): MetaConceptStatusData => {
  const [isOpen, setIsOpen] = useState<MetaConceptStatusData['localState']['isOpen']>(false);

  const localState: MetaConceptStatusData['localState'] = {
    isOpen
  };

  const localActions: MetaConceptStatusData['localActions'] = useMemo(
    () => ({
      setIsOpen
    }),
    []
  );

  const formattedData: MetaConceptStatusData['formattedData'] = useMemo(
    () => ({
      createdDate: uiLib.formatDate(metaConceptDetails?.created_at, {
        format: uiLib.DATE_FORMATS.API_DATE_TIME,
        passedTimezone: uiLib.getLocalTimezone(),
        timezone: uiLib.TIMEZONES.UTC
      }),
      updatedDate: uiLib.formatDate(metaConceptDetails?.updated_at, {
        format: uiLib.DATE_FORMATS.API_DATE_TIME,
        passedTimezone: uiLib.getLocalTimezone(),
        timezone: uiLib.TIMEZONES.UTC
      })
    }),
    [metaConceptDetails?.created_at, metaConceptDetails?.updated_at]
  );

  return {
    localState,
    localActions,
    formattedData
  };
};
