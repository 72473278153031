// ----- TABLE CONSTANTS -----

import {
  ProductServiceDetails,
  ProductServiceDetailsNormalized
} from './ProductServiceDetails.types';

export const PRODUCT_SERVICE_ATTRIBUTES = {
  id: 'id',
  name: 'name',
  description: 'description',
  multiField: 'multi_input',
  isActive: 'is_active',
  createdAt: 'created_at',
  updatedAt: 'updated_at',
  user: 'user',
  isShare: 'is_shared',
  businessLine: 'business_line'
};

export const NEW_PRODUCT_SERVICE_ID = 'new';

// Errors

export const ERRORS = {
  commonError: 'This value is required'
};

// product service details used by ProductServiceDetails component (just help to show structure)
export const PRODUCT_SERVICE_DETAILS: ProductServiceDetailsNormalized = {
  // general info
  name: '', // string
  id: 'new', // number | new
  description: '', // string
  is_active: true, // boolean
  created_at: '', // string
  updated_at: '', // string
  is_shared: false, // boolean
  business_line: null // SelectValue
};

export const PRODUCT_SERVICE_ACTIVE_TYPES = {
  ACTIVE: 'active',
  DEACTIVATED: 'deactivated'
};

export const DELETE_CONFIRMATION_MODAL = {
  title: 'Delete product service',
  body: 'Are you sure you want to delete this product service?'
};
