import type { MetaConceptsHandlers } from './useMetaConceptsHandlers.types';
import type { MetaConceptsData } from './useMetaConceptsData.types';

import ROUTES from 'admin/constants/routes';

import * as uiLib from '@compliance.ai/web-components';
import * as metaConceptDataApi from 'shared/features/metaConcepts/metaConcepts.api';
import * as errorUtils from 'shared/utils/errors';
import * as helpers from './useMetaConceptsHandlers.helpers';

import { useNavigate } from 'react-router-dom';

export const useMetaConceptsHandlers = ({
  localState,
  localActions
}: {
  localState: MetaConceptsData['localState'];
  localActions: MetaConceptsData['localActions'];
}): MetaConceptsHandlers => {
  const navigate = useNavigate();
  const handleMetaConceptsFetch: MetaConceptsHandlers['handleMetaConceptsFetch'] = async (
    params,
    options
  ) => {
    try {
      const metaConceptsResponse = await metaConceptDataApi.api_getMetaConcepts({
        ...params,
        ...localState.filters,
        [uiLib.TABLE_PARAMS.SORT]: helpers.getApiSortSettings(params[uiLib.TABLE_PARAMS.SORT])
      });

      return {
        results: metaConceptsResponse.items,
        count: metaConceptsResponse.count ?? 0
      };
    } catch (e) {
      errorUtils.logError(e);

      return {
        results: [],
        count: 0
      };
    }
  };

  const handleFiltersSubmit: MetaConceptsHandlers['handleFiltersSubmit'] = filters => {
    localActions.setTableParams({
      ...localState.tableParams,
      [uiLib.TABLE_PARAMS.OFFSET]: 0
    });
    localActions.setFilters(filters);
    localActions.setReloadTrigger(reloadTrigger => !reloadTrigger);
  };

  const handleCreateNewMetaConcept: MetaConceptsHandlers['handleCreateNewMetaConcept'] = () => {
    navigate(`${ROUTES.metaConceptTool}/new`);
  };

  return {
    handleMetaConceptsFetch: handleMetaConceptsFetch,
    handleFiltersSubmit,
    handleCreateNewMetaConcept: handleCreateNewMetaConcept
  };
};
