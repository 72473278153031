import type { ProductServicesFiltersHandlers } from './useProductServicesFiltersHandlers.types';
import type { ProductServicesFiltersProps } from '../ProductServicesFilters.types';

import * as helpers from './useProductServicesFiltersHandlers.helpers';

export const useProductServicesFiltersHandlers = ({
  props
}: {
  props: Pick<ProductServicesFiltersProps, 'onSubmit'>;
}): ProductServicesFiltersHandlers => {
  const handleFiltersSubmit: ProductServicesFiltersHandlers['handleFiltersSubmit'] = (
    value,
    searchValue
  ) => {
    props.onSubmit(helpers.formatProductServicesQueryParams(value, searchValue));
  };

  return {
    handleFiltersSubmit
  };
};
