import type { LogsModalProps } from '../LogsModal.types';
import type { LogsModalData } from './useLogsModalData.types';
import type { GlobalState } from 'shared/reducers';

import * as helpers from './useLogsModalData.helpers';
import * as spiderRunReportsSelectors from 'shared/features/spiderRunReports/spiderRunReports.selector';

import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export const useLogsModalData = ({ report }: Pick<LogsModalProps, 'report'>): LogsModalData => {
  const reduxState = useSelector<GlobalState, LogsModalData['reduxState']>(state => ({
    logReport: spiderRunReportsSelectors.getSpiderRunReportItems(state)
  }));

  const formattedData = useMemo(
    () => ({
      rows: helpers.getLogsForTable(reduxState.logReport?.[0] || report)
    }),
    [reduxState.logReport, report]
  );

  return {
    formattedData,
    reduxState
  };
};
