import type { MetaConceptDetailsData } from './useMetaConceptDetailsData.types';
import type { GlobalState } from 'shared/reducers';
import type { CrawlDetailsData } from 'admin/containers/CrawlerPage/CrawlerDetails/hooks/useCrawlDetailsData.types';

import * as metaConceptsSelectors from 'shared/features/metaConcepts/metaConcept.selectors';
import {
  META_CONCEPT_DETAILS,
  NEW_META_CONCEPT_ID
} from 'admin/containers/MetaConceptPage/elements/MetaConceptDetails/MetaConceptDetails.constants';
import { getCurrentUser } from 'shared/features/user/user.selectors';

import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
export const useMetaConceptDetailsData = (): MetaConceptDetailsData => {
  const { id } = useParams();
  const [isOpen, setIsOpen] = useState<MetaConceptDetailsData['localState']['isOpen']>(false);
  const [editMode, setEditMode] = useState<MetaConceptDetailsData['localState']['editMode']>(
    id === NEW_META_CONCEPT_ID
  );

  const [errorFields, setErrorFields] = useState<CrawlDetailsData['localState']['errorFields']>([]);
  const [metaConceptDetails, setMetaConceptDetails] =
    useState<MetaConceptDetailsData['localState']['metaConceptDetails']>(META_CONCEPT_DETAILS);

  const localState: MetaConceptDetailsData['localState'] = {
    isOpen,
    editMode,
    metaConceptDetails,
    errorFields
  };

  const localActions: MetaConceptDetailsData['localActions'] = useMemo(
    () => ({
      setIsOpen,
      setMetaConceptDetails,
      setEditMode,
      setErrorFields
    }),
    []
  );
  const reduxState: MetaConceptDetailsData['reduxState'] = useSelector<
    GlobalState,
    MetaConceptDetailsData['reduxState']
  >(state => ({
    metaConcept: metaConceptsSelectors.getFirstMetaConcept(state),
    currentUser: getCurrentUser(state)
  }));

  const formattedData: MetaConceptDetailsData['formattedData'] = useMemo(
    () => ({
      metaConceptId: String(id),
      isNewMode: id === 'new'
    }),
    [id]
  );

  return {
    localState,
    localActions,
    formattedData,
    reduxState
  };
};
