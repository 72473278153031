import { useDispatch } from 'react-redux';
import { useCallback, useMemo } from 'react';

import * as actions from '../metaConcepts.actions';

export const useMetaConceptsReduxActions = () => {
  const dispatch = useDispatch();

  const addMetaConcept = useCallback(
    async (...args) => {
      return dispatch(actions.createMetaConcepts(...args));
    },
    [dispatch]
  );
  const fetchMetaConcept = useCallback(
    async (...args) => {
      return dispatch(actions.getMetaConcepts(...args));
    },
    [dispatch]
  );

  const updateMetaConcept = useCallback(
    async (...args) => {
      return dispatch(actions.updateMetaConcepts(...args));
    },
    [dispatch]
  );

  const clearMetaConcepts = useCallback(
    async (...args) => {
      return dispatch(actions.clearMetaConcepts(...args));
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      addMetaConcept: addMetaConcept,
      clearMetaConcepts: clearMetaConcepts,
      fetchMetaConcept: fetchMetaConcept,
      updateMetaConcept: updateMetaConcept
    }),
    [addMetaConcept, clearMetaConcepts, fetchMetaConcept, updateMetaConcept]
  );
};
