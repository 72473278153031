import type { MetaConceptsTableConfig } from './useMetaConceptsTableConfig.types';

import * as uiLib from '@compliance.ai/web-components';
import * as metaConceptsConstants from 'admin/constants/metaConcepts';
import * as elements from '../elements';

export const useMetaConceptsTableConfig = (): MetaConceptsTableConfig => {
  const columns: uiLib.Column[] = [
    {
      title: 'Name',
      dataKey: metaConceptsConstants.META_CONCEPTS_ATTRIBUTES.NAME,
      sortKey: metaConceptsConstants.META_CONCEPTS_ATTRIBUTES.NAME,
      isDynamic: true,
      isInitiallyVisible: true,
      Component: uiLib.LinkCell,
      width: uiLib.TABLE_COLUMN_WIDTH.S
    },
    {
      title: 'ID',
      dataKey: metaConceptsConstants.META_CONCEPTS_ATTRIBUTES.ID,
      sortKey: metaConceptsConstants.META_CONCEPTS_ATTRIBUTES.ID,
      isDynamic: false,
      Component: uiLib.TextCell,
      width: uiLib.TABLE_COLUMN_WIDTH.XS
    },
    {
      title: 'Status',
      dataKey: metaConceptsConstants.META_CONCEPTS_ATTRIBUTES.IS_ACTIVE,
      sortKey: metaConceptsConstants.META_CONCEPTS_ATTRIBUTES.IS_ACTIVE,
      isDynamic: true,
      isInitiallyVisible: true,
      Component: elements.MetaConceptStatus,
      width: uiLib.TABLE_COLUMN_WIDTH.S
    },
    {
      title: 'Updated',
      dataKey: metaConceptsConstants.META_CONCEPTS_ATTRIBUTES.UPDATED_AT,
      sortKey: metaConceptsConstants.META_CONCEPTS_ATTRIBUTES.UPDATED_AT,
      isDynamic: true,
      isInitiallyVisible: false,
      Component: uiLib.TextCell,
      width: uiLib.TABLE_COLUMN_WIDTH.S
    },
    {
      title: 'Description',
      dataKey: metaConceptsConstants.META_CONCEPTS_ATTRIBUTES.DESCRIPTION,
      sortKey: metaConceptsConstants.META_CONCEPTS_ATTRIBUTES.DESCRIPTION,
      isDynamic: true,
      isInitiallyVisible: false,
      Component: uiLib.TextCell,
      width: uiLib.TABLE_COLUMN_WIDTH.S
    }
  ];

  return {
    columns
  };
};
