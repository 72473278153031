import type { ProductServiceStatusListConfig } from './useProductServiceStatusListConfig.types';

import * as constants from '../ProductServiceStatus.constants';
import * as uiLib from '@compliance.ai/web-components';

import { useMemo } from 'react';

export const useProductServiceStatusListConfig = (): ProductServiceStatusListConfig => {
  const options: ProductServiceStatusListConfig['options'] = useMemo(
    () => [
      {
        label: (
          <div className="sources_status_status-block_option">
            <uiLib.IconCheck />
            <uiLib.Typography>Activate</uiLib.Typography>
          </div>
        ),
        value: constants.PRODUCT_SERVICE_CREATION_VALUES.ACTIVE
      },
      {
        label: (
          <div className="sources_status_status-block_option">
            <uiLib.IconWarning />
            <uiLib.Typography>Deactivate</uiLib.Typography>
          </div>
        ),
        value: constants.PRODUCT_SERVICE_CREATION_VALUES.DEACTIVATED
      }
    ],
    []
  );

  return {
    options
  };
};
