import type { MetaConceptsFiltersHandlers } from './useMetaConceptsFiltersHandlers.types';
import type { MetaConceptsFiltersProps } from '../MetaConceptsFilters.types';

import * as helpers from './useMetaConceptsFiltersHandlers.helpers';

export const useMetaConceptsFiltersHandlers = ({
  props
}: {
  props: Pick<MetaConceptsFiltersProps, 'onSubmit'>;
}): MetaConceptsFiltersHandlers => {
  const handleFiltersSubmit: MetaConceptsFiltersHandlers['handleFiltersSubmit'] = (
    value,
    searchValue
  ) => {
    props.onSubmit(helpers.formatMetaConceptsQueryParams(value, searchValue));
  };

  return {
    handleFiltersSubmit
  };
};
