import type { FC } from 'react';
import type { MetaConceptStatusProps } from './MetaConceptStatus.types';
import type { MetaConceptFromResponse } from 'shared/features/metaConcepts/metaConcepts.types';

import Badge from 'admin/components/Badge';
import { getMetaConceptStatusBadgeInfo } from './MetaConceptStatus.helpers';

export const MetaConceptStatus: FC<MetaConceptStatusProps> = ({ row }) => {
  const conceptValue = row as MetaConceptFromResponse;
  const badgeInfo = getMetaConceptStatusBadgeInfo(conceptValue?.is_active);
  return (
    <Badge type={badgeInfo.type} size="medium" title={badgeInfo.text}>
      {badgeInfo.text}
    </Badge>
  );
};
