import type { MetaConceptPageStatusProps } from '../MetaConceptStatus.types';
import type { MetaConceptStatusData } from './useMetaConceptStatusData.types';
import type { MetaConceptPageStatusHandlers } from './useMetaConceptStatusHandlers.types';

import ROUTES from 'admin/constants/routes';
import * as constants from '../MetaConceptStatus.constants';

import { useHistory } from 'admin/utils/hooks';
import { useLocation } from 'react-router-dom';

export const useMetaConceptStatusHandlers = ({
  props,
  localActions
}: {
  props: Pick<MetaConceptPageStatusProps, 'onChangeStatus'>;
  localActions: MetaConceptStatusData['localActions'];
}): MetaConceptPageStatusHandlers => {
  const history = useHistory();
  const location = useLocation();

  const handleStatusDropdownToggle: MetaConceptPageStatusHandlers['handleStatusDropdownToggle'] =
    isOpen => () => {
      localActions.setIsOpen(isOpen);
    };

  const handleListOptionClick: MetaConceptPageStatusHandlers['handleListOptionClick'] = ([
    option
  ]) => {
    switch (option.value) {
      case constants.META_CONCEPT_CREATION_VALUES.ACTIVE: {
        props.onChangeStatus(true);
        break;
      }

      case constants.META_CONCEPT_CREATION_VALUES.DEACTIVATED: {
        props.onChangeStatus(false);
        break;
      }
    }

    localActions.setIsOpen(false);
  };

  const handleGoBack: MetaConceptPageStatusHandlers['handleGoBack'] = () => {
    history.push(`${ROUTES.contentManagement}?tab=meta_concepts`, location.state);
  };

  return {
    handleStatusDropdownToggle,
    handleListOptionClick,
    handleGoBack
  };
};
