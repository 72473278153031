import React from 'react';
import PropTypes from 'prop-types';
import Typography from '../../../components/Typography';
import Button from '../../../components/Button';
import TextInput from '../../../components/TextInput';
import { CONCEPT_ATTRIBUTES } from './ConceptDetails.constants';
import './ConceptDetails.styles.scss';
import AsyncSelect from '../../../components/ReactSelect/SelectAsync';
import { loadMetaConceptOptions } from './ConceptDetails.helper';

const ConceptDetailsGeneral = ({
  editMode,
  toggleEditMode,
  details,
  saveChanges,
  handleChanges,
  handleChangesEvent,
  errorFields,
  isNewMode,
  handleDelete
}) => {
  const disableEdit = !editMode;
  return (
    <div className="concept-details">
      <div className="concept-details_body">
        <div className="general-info">
          <div className="general-info_header">
            <Typography variant="h5">General info</Typography>
            <div className="general-info_header_btn-block">
              {!isNewMode && (
                <Button size="custom" color="error" onClick={handleDelete}>
                  Delete
                </Button>
              )}
              <Button size="custom" color="highlighted" onClick={toggleEditMode}>
                {editMode ? 'Cancel' : 'Edit'}
              </Button>
              {editMode && (
                <Button size="custom" color="success" onClick={saveChanges}>
                  Save Changes
                </Button>
              )}
            </div>
          </div>
          <div className="general-info_body">
            {/* First row */}
            <TextInput
              className="input_4"
              label="Name"
              name={CONCEPT_ATTRIBUTES.name}
              value={details[CONCEPT_ATTRIBUTES.name]}
              isDisabled={disableEdit}
              onChange={handleChangesEvent}
              isError={errorFields.includes(CONCEPT_ATTRIBUTES.name)}
            />
            <TextInput
              className="input_2"
              label="ID"
              isDisabled
              name={CONCEPT_ATTRIBUTES.id}
              value={details[CONCEPT_ATTRIBUTES.id]}
              onChange={handleChangesEvent}
              isError={errorFields.includes(CONCEPT_ATTRIBUTES.id)}
            />
            {/* Second row */}
            <AsyncSelect
              className="input_4"
              label="Associated Meta-Concept"
              isDisabled={disableEdit}
              value={details[CONCEPT_ATTRIBUTES.metaConcept]}
              onChange={e => handleChanges(CONCEPT_ATTRIBUTES.metaConcept, e)}
              loadOptions={value => loadMetaConceptOptions('name', value, 'name')}
              isClearable
            />
            <TextInput
              className="input_4"
              label="Description"
              isDisabled={disableEdit}
              name={CONCEPT_ATTRIBUTES.description}
              value={details[CONCEPT_ATTRIBUTES.description]}
              onChange={handleChangesEvent}
              isError={errorFields.includes(CONCEPT_ATTRIBUTES.description)}
            />
            {/* Third row */}
            <TextInput
              className="input_8"
              label="Advanced Search"
              isDisabled={disableEdit}
              name={CONCEPT_ATTRIBUTES.advancedSearch}
              value={details[CONCEPT_ATTRIBUTES.advancedSearch]}
              onChange={handleChangesEvent}
              isError={errorFields.includes(CONCEPT_ATTRIBUTES.advancedSearch)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

ConceptDetailsGeneral.propTypes = {
  editMode: PropTypes.bool,
  toggleEditMode: PropTypes.func.isRequired,
  details: PropTypes.shape({}),
  saveChanges: PropTypes.func.isRequired,
  handleChanges: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleChangesEvent: PropTypes.func.isRequired,
  errorFields: PropTypes.arrayOf(PropTypes.string),
  isNewMode: PropTypes.bool
};

ConceptDetailsGeneral.defaultProps = {
  editMode: false,
  details: {},
  errorFields: [],
  isNewMode: false
};

export default ConceptDetailsGeneral;
