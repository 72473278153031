import type { MetaConceptEditHandlers } from './useMetaConceptPageHandlers.types';

import { useEffect } from 'react';

export const useMetaConceptPageLifecycle = ({
  onInitialDataFetch
}: {
  onInitialDataFetch: MetaConceptEditHandlers['handleLoadMetaConcept'];
}) => {
  useEffect(() => {
    onInitialDataFetch();
  }, [onInitialDataFetch]);
};
