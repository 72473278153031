import * as productServiceElements from 'admin/containers/ProductServicePage/elements';
import * as elements from './elements';
import * as constants from './ProductServiceDetails.constants';
import Tabs from 'admin/components/Tabs';
import ConfirmationModal from 'admin/containers/CrawlerPage/ConfirmationModal';

import {
  useProductServiceDetailsHandlers,
  useProductServiceDetailsData,
  useProductServiceDetailsLifecycle
} from './hooks';

import './ProductServiceDetails.styles.scss';

const ProductServiceDetails = ({}) => {
  const { localState, localActions, formattedData, reduxState } = useProductServiceDetailsData();
  const detailsHandlers = useProductServiceDetailsHandlers({
    localActions,
    localState,
    formattedData,
    reduxState
  });
  useProductServiceDetailsLifecycle({
    onCalculateProductServiceDetails: detailsHandlers.handleCalculateProductService
  });

  return (
    <>
      <Tabs
        className="product-services-navigation_tabs"
        headClassName="product-services-navigation_head"
        tabClassName="product-services-navigation_tab"
        extraInfo={
          <productServiceElements.ProductServiceStatus
            productServiceDetails={localState.productServiceDetails}
            onChangeStatus={detailsHandlers.handleStatusDropdownToggle}
          />
        }
      >
        <elements.ProductServiceDetailsGeneral
          label={'Area of Focus Details'}
          editMode={localState.editMode}
          toggleEditMode={detailsHandlers.handleToggleEditMode}
          details={localState.productServiceDetails}
          saveChanges={detailsHandlers.handleSaveChanges}
          handleChanges={detailsHandlers.handleChanges}
          errorFields={localState.errorFields}
          isNewMode={formattedData.isNewMode}
          handleDelete={detailsHandlers.handleToggleConfirmationModal}
        />
      </Tabs>
      <ConfirmationModal
        show={localState.isOpen}
        mode={constants.DELETE_CONFIRMATION_MODAL}
        onHide={detailsHandlers.handleToggleConfirmationModal}
        modalAction={detailsHandlers.handleOnDelete}
      />
    </>
  );
};

export default ProductServiceDetails;
