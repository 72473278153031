import type { ProductServiceEditHandlers } from './useProductServicePageHandlers.types';

import { useEffect } from 'react';

export const useProductServicePageLifecycle = ({
  onInitialDataFetch
}: {
  onInitialDataFetch: ProductServiceEditHandlers['handleLoadProductService'];
}) => {
  useEffect(onInitialDataFetch, [onInitialDataFetch]);
};
