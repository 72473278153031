import type { ProductServicesHandlers } from './useProductServicesHandlers.types';
import type { ComponentProps } from 'react';
import type { ProductServiceFromResponse } from 'shared/features/productServices/productServices.types';
import type { BusinessLineFromResponse } from 'shared/features/businessLines/businessLines.types';

import ROUTES from 'admin/constants/routes';

import * as uiLib from '@compliance.ai/web-components';
import * as productServicesConstants from 'admin/constants/productServices';

export const useProductServicesSelector = () => {
  return (
    data: Awaited<ReturnType<ProductServicesHandlers['handleProductServicesFetch']>>
  ): Awaited<ReturnType<Required<ComponentProps<typeof uiLib.Table>>['onDataFetch']>> => {
    return {
      count: data.count,
      results: data.results.map(row => {
        const product_service = row as ProductServiceFromResponse;
        return {
          [productServicesConstants.PRODUCT_SERVICES_ATTRIBUTES.ID]:
            product_service[productServicesConstants.PRODUCT_SERVICES_ATTRIBUTES.ID],
          [productServicesConstants.PRODUCT_SERVICES_ATTRIBUTES.NAME]: {
            href: `${window.location.origin}${ROUTES.productServiceTool}/${
              product_service[productServicesConstants.PRODUCT_SERVICES_ATTRIBUTES.ID]
            }`,
            tooltip: product_service[productServicesConstants.PRODUCT_SERVICES_ATTRIBUTES.NAME],
            children: product_service[productServicesConstants.PRODUCT_SERVICES_ATTRIBUTES.NAME]
          } as uiLib.LinkCellValue,
          [productServicesConstants.PRODUCT_SERVICES_ATTRIBUTES.AGENCIES]: {
            href: `${window.location.origin}${ROUTES.productServiceTool}/${
              product_service[productServicesConstants.PRODUCT_SERVICES_ATTRIBUTES.ID]
            }`,
            tooltip: product_service[productServicesConstants.PRODUCT_SERVICES_ATTRIBUTES.AGENCIES],
            children: product_service[productServicesConstants.PRODUCT_SERVICES_ATTRIBUTES.AGENCIES]
          } as uiLib.LinkCellValue,
          [productServicesConstants.PRODUCT_SERVICES_ATTRIBUTES.CONCEPTS]: {
            href: `${window.location.origin}${ROUTES.productServiceTool}/${
              product_service[productServicesConstants.PRODUCT_SERVICES_ATTRIBUTES.ID]
            }`,
            tooltip: product_service[productServicesConstants.PRODUCT_SERVICES_ATTRIBUTES.CONCEPTS],
            children: product_service[productServicesConstants.PRODUCT_SERVICES_ATTRIBUTES.CONCEPTS]
          } as uiLib.LinkCellValue,
          [productServicesConstants.PRODUCT_SERVICES_ATTRIBUTES.NEWS_SOURCES]: {
            href: `${window.location.origin}${ROUTES.productServiceTool}/${
              product_service[productServicesConstants.PRODUCT_SERVICES_ATTRIBUTES.ID]
            }`,
            tooltip:
              product_service[productServicesConstants.PRODUCT_SERVICES_ATTRIBUTES.NEWS_SOURCES],
            children:
              product_service[productServicesConstants.PRODUCT_SERVICES_ATTRIBUTES.NEWS_SOURCES]
          } as uiLib.LinkCellValue,
          [productServicesConstants.PRODUCT_SERVICES_ATTRIBUTES.TOPICS]: {
            href: `${window.location.origin}${ROUTES.productServiceTool}/${
              product_service[productServicesConstants.PRODUCT_SERVICES_ATTRIBUTES.ID]
            }`,
            tooltip: product_service[productServicesConstants.PRODUCT_SERVICES_ATTRIBUTES.TOPICS],
            children: product_service[productServicesConstants.PRODUCT_SERVICES_ATTRIBUTES.TOPICS]
          } as uiLib.LinkCellValue,
          [productServicesConstants.PRODUCT_SERVICES_ATTRIBUTES.CREATED_AT]:
            product_service[productServicesConstants.PRODUCT_SERVICES_ATTRIBUTES.CREATED_AT],
          [productServicesConstants.PRODUCT_SERVICES_ATTRIBUTES.UPDATED_AT]:
            product_service[productServicesConstants.PRODUCT_SERVICES_ATTRIBUTES.UPDATED_AT],
          [productServicesConstants.PRODUCT_SERVICES_ATTRIBUTES.BUSINESS_LINE]: (
            product_service[
              productServicesConstants.PRODUCT_SERVICES_ATTRIBUTES.BUSINESS_LINE
            ] as BusinessLineFromResponse
          )?.name,
          [productServicesConstants.PRODUCT_SERVICES_ATTRIBUTES.IS_ACTIVE]:
            product_service[productServicesConstants.PRODUCT_SERVICES_ATTRIBUTES.IS_ACTIVE]
        };
      })
    };
  };
};
