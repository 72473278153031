import { createSlice } from '@reduxjs/toolkit';

export const reducerName = 'businessLines';
export const initialState = { items: [], count: 0, timestamp: Date().toString(), load: false };

const businessLinesReducer = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    startLoad: (state, action) => ({
      ...state,
      load: true
    }),
    finishLoad: (state, action) => ({
      ...state,
      timestamp: Date().toString(),
      load: false
    }),
    setBusinessLine: (state, action) => {
      return action.payload;
    },
    createBusinessLine: (state, action) => ({
      ...state,
      count: state.count + 1,
      items: [...state.items, action.payload]
    }),
    updateBusinessLine: (state, action) => ({
      ...state,
      items: state.items.map(item => (item.id === action.payload.id ? action.payload : item))
    }),
    deleteBusinessLine: (state, action) => ({
      ...state,
      count: state.count - 1,
      items: state.items.filter(item => item.id !== action.payload.id)
    })
  }
});

export default businessLinesReducer.reducer;
export const {
  startLoad,
  finishLoad,
  setBusinessLine,
  createBusinessLine,
  updateBusinessLine,
  deleteBusinessLine
} = businessLinesReducer.actions;
