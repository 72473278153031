import { useDispatch } from 'react-redux';
import { useCallback, useMemo } from 'react';

import * as actions from '../productServices.actions';

export const useProductServicesReduxActions = () => {
  const dispatch = useDispatch();

  const addProductService = useCallback(
    async (...args) => {
      return dispatch(actions.createProductServices(...args));
    },
    [dispatch]
  );
  const fetchProductService = useCallback(
    async (...args) => {
      return dispatch(actions.getProductServices(...args));
    },
    [dispatch]
  );

  const updateProductService = useCallback(
    async (...args) => {
      return dispatch(actions.updateProductServices(...args));
    },
    [dispatch]
  );

  const clearProductServices = useCallback(
    async (...args) => {
      return dispatch(actions.clearProductServices(...args));
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      addProductService: addProductService,
      clearProductServices: clearProductServices,
      fetchProductService: fetchProductService,
      updateProductService: updateProductService
    }),
    [addProductService, clearProductServices, fetchProductService, updateProductService]
  );
};
