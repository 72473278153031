import type { ProductServicesApi } from './useProductServicesApi.types';

import * as api from '../productServices.api';
import * as helpers from '../productServices.api.helpers';
import * as errorUtils from 'shared/utils/errors';

import { StatusCodes } from 'http-status-codes';

export const useProductServicesApi = (): ProductServicesApi => {
  const getProductServices: ProductServicesApi['getProductServices'] = async params => {
    try {
      const response = await api.api_getProductServices(params);

      return helpers.normalizeProductServicesResponse(response);
    } catch (e) {
      errorUtils.logError(e as Error);
      throw e;
    }
  };

  const addProductService: ProductServicesApi['addProductService'] = async params => {
    try {
      const response = await api.api_postProductServices(params);

      return helpers.normalizeProductService(response);
    } catch (e) {
      const { status } = e as never;

      const isValidationError = status === StatusCodes.CONFLICT;

      // we used to call uilib showNotification method here , but now
      // we just rely on the caller that catches and handles the exception
      // in any case; this way avoid double notification

      errorUtils.logError(e as Error, 'Error', { isSilent: isValidationError });
      throw e;
    }
  };

  const modifyProductService: ProductServicesApi['modifyProductService'] = async (id, params) => {
    try {
      const response = await api.api_putProductServices(id, params);

      return helpers.normalizeProductService(response);
    } catch (e) {
      const { status } = e as never;

      const isValidationError = status === StatusCodes.CONFLICT;

      // we used to call uilib showNotification method here , but now
      // we just rely on the caller that catches and handles the exception
      // in any case; this way avoid double notification

      errorUtils.logError(e as Error, 'Error', { isSilent: isValidationError });
      throw e;
    }
  };

  const deleteProductService: ProductServicesApi['deleteProductService'] = async id => {
    try {
      return await api.api_deleteProductServices(id);
    } catch (e) {
      errorUtils.logError(e as Error, 'Error');
      throw e;
    }
  };

  return {
    getProductServices,
    addProductService,
    modifyProductService,
    deleteProductService
  };
};
