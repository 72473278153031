import type { GlobalState } from 'shared/reducers';
import type { ProductServiceFromResponseV2 } from './productServices.api.types';

import { createSelector } from 'reselect';

export const getProductServicesReducer = createSelector(
  (state: GlobalState) => state,
  (state: GlobalState) => state.productServices
);

export const getProductServices = createSelector(
  getProductServicesReducer,
  (currentProductServicesReducer): ProductServiceFromResponseV2[] => {
    return currentProductServicesReducer?.items;
  }
);

export const getFirstProductService = createSelector(
  getProductServices,
  (ProductServices: ProductServiceFromResponseV2[]): ProductServiceFromResponseV2 =>
    ProductServices?.[0]
);
