import type { GlobalState } from 'shared/reducers';
import type { ProductServicePageData } from './useProductServicePageData.types';

import * as productServicesSelectors from 'shared/features/productServices/productService.selectors';

import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

export const useProductServicePageData = (): ProductServicePageData => {
  const { id } = useParams();

  const reduxState: ProductServicePageData['reduxState'] = useSelector<
    GlobalState,
    ProductServicePageData['reduxState']
  >(state => ({
    productService: productServicesSelectors.getFirstProductService(state)
  }));

  const formattedData: ProductServicePageData['formattedData'] = useMemo(
    () => ({
      productServiceId: id,
      isActive: reduxState.productService?.is_active
    }),
    [id, reduxState.productService?.is_active]
  );

  return {
    reduxState,
    formattedData
  };
};
