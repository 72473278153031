import type { SpiderRunReportFromResponse } from 'shared/features/spiderRunReports/spiderRunReports.api.types';
import type { FC } from 'react';
import type { LogsLinkCellProps } from './LogsLinkCell.types';

import * as uiLib from '@compliance.ai/web-components';
import * as elements from './elements';

import { useLogsLinkCellData, useLogsLinkCellHandlers } from './hooks';

export const LogsLinkCell: FC<LogsLinkCellProps> = ({ row }) => {
  const { localState, localActions, formattedData } = useLogsLinkCellData({ row });

  const handlers = useLogsLinkCellHandlers({
    localActions,
    formattedData
  });

  return (
    <>
      <uiLib.Button type={uiLib.BUTTON_TYPES.LINK} onClick={handlers.handleModalToggle(true)}>
        {formattedData.logsTotalCount}
      </uiLib.Button>
      {localState.isModalOpen && (
        <elements.LogsModal
          report={row as SpiderRunReportFromResponse}
          onClose={handlers.handleModalToggle(false)}
        />
      )}
    </>
  );
};
