import { useEffect } from 'react';
import type { MetaConceptDetailsHandlers } from './useMetaConceptDetailsHandlers.types';

export const useMetaConceptDetailsLifecycle = ({
  onCalculateMetaConceptDetails
}: {
  onCalculateMetaConceptDetails: MetaConceptDetailsHandlers['handleCalculateMetaConcept'];
}): void => {
  useEffect(onCalculateMetaConceptDetails, [onCalculateMetaConceptDetails]);
};
