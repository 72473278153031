import type { ProductServiceDetailsData } from './useProductServiceDetailsData.types';
import type { GlobalState } from 'shared/reducers';
import type { CrawlDetailsData } from 'admin/containers/CrawlerPage/CrawlerDetails/hooks/useCrawlDetailsData.types';

import * as productServicesSelectors from 'shared/features/productServices/productService.selectors';
import {
  PRODUCT_SERVICE_DETAILS,
  NEW_PRODUCT_SERVICE_ID
} from 'admin/containers/ProductServicePage/elements/ProductServiceDetails/ProductServiceDetails.constants';
import { getCurrentUser } from 'shared/features/user/user.selectors';

import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
export const useProductServiceDetailsData = (): ProductServiceDetailsData => {
  const { id } = useParams();
  const [isOpen, setIsOpen] = useState<ProductServiceDetailsData['localState']['isOpen']>(false);
  const [editMode, setEditMode] = useState<ProductServiceDetailsData['localState']['editMode']>(
    id === NEW_PRODUCT_SERVICE_ID
  );

  const [errorFields, setErrorFields] = useState<CrawlDetailsData['localState']['errorFields']>([]);
  const [productServiceDetails, setProductServiceDetails] =
    useState<ProductServiceDetailsData['localState']['productServiceDetails']>(
      PRODUCT_SERVICE_DETAILS
    );

  const localState: ProductServiceDetailsData['localState'] = {
    isOpen,
    editMode,
    productServiceDetails,
    errorFields
  };

  const localActions: ProductServiceDetailsData['localActions'] = useMemo(
    () => ({
      setIsOpen,
      setProductServiceDetails,
      setEditMode,
      setErrorFields
    }),
    []
  );
  const reduxState: ProductServiceDetailsData['reduxState'] = useSelector<
    GlobalState,
    ProductServiceDetailsData['reduxState']
  >(state => ({
    productService: productServicesSelectors.getFirstProductService(state),
    currentUser: getCurrentUser(state)
  }));

  const formattedData: ProductServiceDetailsData['formattedData'] = useMemo(
    () => ({
      productServiceId: String(id),
      isNewMode: id === 'new'
    }),
    [id]
  );

  return {
    localState,
    localActions,
    formattedData,
    reduxState
  };
};
