import { createSlice } from '@reduxjs/toolkit';

export const reducerName = 'productServices';
export const initialState = { items: [], count: 0, timestamp: Date().toString(), load: false };

const productServicesReducer = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    startLoad: (state, action) => ({
      ...state,
      load: true
    }),
    finishLoad: (state, action) => ({
      ...state,
      timestamp: Date().toString(),
      load: false
    }),
    setProductService: (state, action) => {
      return action.payload;
    },
    createProductService: (state, action) => ({
      ...state,
      count: state.count + 1,
      items: [...state.items, action.payload]
    }),
    updateProductService: (state, action) => ({
      ...state,
      items: state.items.map(item => (item.id === action.payload.id ? action.payload : item))
    }),
    deleteProductService: (state, action) => ({
      ...state,
      count: state.count - 1,
      items: state.items.filter(item => item.id !== action.payload.id)
    })
  }
});

export default productServicesReducer.reducer;
export const {
  startLoad,
  finishLoad,
  setProductService,
  createProductService,
  updateProductService,
  deleteProductService
} = productServicesReducer.actions;
