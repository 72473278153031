import type { FC } from 'react';
import type { MetaConceptsFiltersProps } from './MetaConceptsFilters.types';

import * as uiLib from '@compliance.ai/web-components';

import { useMetaConceptsFiltersConfig, useMetaConceptsFiltersHandlers } from './hooks';

import './_meta_concepts-filters.scss';

export const MetaConceptsFilters: FC<MetaConceptsFiltersProps> = ({ onSubmit }) => {
  const filterConfig = useMetaConceptsFiltersConfig();

  const handlers = useMetaConceptsFiltersHandlers({
    props: {
      onSubmit
    }
  });

  return (
    <uiLib.Filters
      config={filterConfig}
      values={{}}
      withSearch
      searchPlaceholder="Search Meta Concept"
      onSubmit={handlers.handleFiltersSubmit}
      dateFormat={uiLib.getLocalDateFormat()}
      className="meta_concepts-filters__container"
    />
  );
};
