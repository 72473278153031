import React, { FC } from 'react';

import * as uiLib from '@compliance.ai/web-components';
import * as constants from './MetaConcepts.constants';
import * as conceptsConstants from 'admin/constants/metaConcepts';
import * as elements from './elements';
import * as errorUtils from 'shared/utils/errors';

import './_meta_concepts.scss';

import {
  useMetaConceptsData,
  useMetaConceptsHandlers,
  useMetaConceptsTableConfig,
  useMetaConceptsSelector
} from './hooks';

const MetaConcepts: FC = () => {
  const { localState, localActions } = useMetaConceptsData();
  const handlers = useMetaConceptsHandlers({ localState, localActions });

  const tableConfig = useMetaConceptsTableConfig();

  const tableDataSelector = useMetaConceptsSelector();

  return (
    <uiLib.ErrorBoundary>
      <div className="meta_concepts__container">
        <div className="meta_concepts__filters">
          <elements.MetaConceptsFilters onSubmit={handlers.handleFiltersSubmit} />
          <uiLib.Button onClick={handlers.handleCreateNewMetaConcept}>
            Add Meta Concept
          </uiLib.Button>
        </div>
        <div className="meat_concepts__table-wrapper">
          <uiLib.Table
            shouldShowTableOptions
            externalParams={localState.tableParams}
            rowKey={conceptsConstants.META_CONCEPTS_ATTRIBUTES.ID}
            columns={tableConfig.columns}
            onDataFetch={handlers.handleMetaConceptsFetch}
            onParamsChange={localActions.setTableParams}
            reloadTrigger={localState.reloadTrigger}
            localStorageKey={constants.TABLE_LOCAL_STORAGE_KEY}
            shouldShowColumnsSettingsButton
            shouldShowFirstPageButton
            shouldShowLastPageButton
            maxAllowedRowsCount={constants.ALLOWED_SELECTED_META_CONCEPTS_COUNT}
            rowsPerPageOptions={constants.ROWS_PER_PAGE_OPTIONS}
            initialRowsPerPage={constants.ROWS_PER_PAGE_OPTIONS[0]}
            queryOptions={{
              select: tableDataSelector,
              onError: errorUtils.logReactQueryError
            }}
            classNames={{
              container: 'meta_concepts__table-outer-container',
              tableContainer: 'meta_concepts__table-inner-container',
              toolbar: 'meta_concepts__table-toolbar',
              headerCell: 'meta_concepts__table-header-cell',
              selectedRowsCount: 'meta_concepts__table-selected-rows-count'
            }}
          />
        </div>
      </div>
    </uiLib.ErrorBoundary>
  );
};

export default MetaConcepts;
