import ProductServiceDetails from './elements/ProductServiceDetails/ProductServiceDetails';

import {
  useProductServicePageData,
  useProductServicePageHandlers,
  useProductServicePageLifecycle
} from './hooks';

const ProductServicePage = () => {
  const { formattedData, reduxState } = useProductServicePageData();
  const handlers = useProductServicePageHandlers({ formattedData });
  useProductServicePageLifecycle({ onInitialDataFetch: handlers.handleLoadProductService });

  return <ProductServiceDetails />;
};

export default ProductServicePage;
