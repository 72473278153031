import type { ProductServicesTableConfig } from './useProductServicesTableConfig.types';

import * as uiLib from '@compliance.ai/web-components';
import * as productServicesConstants from 'admin/constants/productServices';
import * as elements from '../elements';

export const useProductServicesTableConfig = (): ProductServicesTableConfig => {
  const columns: uiLib.Column[] = [
    {
      title: 'Name',
      dataKey: productServicesConstants.PRODUCT_SERVICES_ATTRIBUTES.NAME,
      sortKey: productServicesConstants.PRODUCT_SERVICES_ATTRIBUTES.NAME,
      isDynamic: true,
      isInitiallyVisible: true,
      Component: uiLib.LinkCell,
      width: uiLib.TABLE_COLUMN_WIDTH.S
    },
    {
      title: 'ID',
      dataKey: productServicesConstants.PRODUCT_SERVICES_ATTRIBUTES.ID,
      sortKey: productServicesConstants.PRODUCT_SERVICES_ATTRIBUTES.ID,
      isDynamic: false,
      Component: uiLib.TextCell,
      width: uiLib.TABLE_COLUMN_WIDTH.XS
    },
    {
      title: 'Meta Area of Focus',
      dataKey: productServicesConstants.PRODUCT_SERVICES_ATTRIBUTES.BUSINESS_LINE,
      sortKey: productServicesConstants.PRODUCT_SERVICES_ATTRIBUTES.BUSINESS_LINE,
      isDynamic: true,
      isInitiallyVisible: true,
      Component: uiLib.TextCell,
      width: uiLib.TABLE_COLUMN_WIDTH.S
    },
    {
      title: 'Status',
      dataKey: productServicesConstants.PRODUCT_SERVICES_ATTRIBUTES.IS_ACTIVE,
      sortKey: productServicesConstants.PRODUCT_SERVICES_ATTRIBUTES.IS_ACTIVE,
      isDynamic: true,
      isInitiallyVisible: true,
      Component: elements.ProductServiceStatus,
      width: uiLib.TABLE_COLUMN_WIDTH.S
    },
    {
      title: 'Updated',
      dataKey: productServicesConstants.PRODUCT_SERVICES_ATTRIBUTES.UPDATED_AT,
      sortKey: productServicesConstants.PRODUCT_SERVICES_ATTRIBUTES.UPDATED_AT,
      isDynamic: true,
      isInitiallyVisible: false,
      Component: uiLib.TextCell,
      width: uiLib.TABLE_COLUMN_WIDTH.S
    },
    {
      title: 'Description',
      dataKey: productServicesConstants.PRODUCT_SERVICES_ATTRIBUTES.DESCRIPTION,
      sortKey: productServicesConstants.PRODUCT_SERVICES_ATTRIBUTES.DESCRIPTION,
      isDynamic: true,
      isInitiallyVisible: false,
      Component: uiLib.TextCell,
      width: uiLib.TABLE_COLUMN_WIDTH.S
    },
    {
      title: 'Agencies',
      dataKey: productServicesConstants.PRODUCT_SERVICES_ATTRIBUTES.AGENCIES,
      sortKey: productServicesConstants.PRODUCT_SERVICES_ATTRIBUTES.AGENCIES,
      isDynamic: true,
      isInitiallyVisible: true,
      Component: uiLib.LinkCell,
      width: uiLib.TABLE_COLUMN_WIDTH.XS
    },
    {
      title: 'Concepts',
      dataKey: productServicesConstants.PRODUCT_SERVICES_ATTRIBUTES.CONCEPTS,
      sortKey: productServicesConstants.PRODUCT_SERVICES_ATTRIBUTES.CONCEPTS,
      isDynamic: true,
      isInitiallyVisible: true,
      Component: uiLib.LinkCell,
      width: uiLib.TABLE_COLUMN_WIDTH.XS
    },
    {
      title: 'News Sources',
      dataKey: productServicesConstants.PRODUCT_SERVICES_ATTRIBUTES.NEWS_SOURCES,
      sortKey: productServicesConstants.PRODUCT_SERVICES_ATTRIBUTES.NEWS_SOURCES,
      isDynamic: true,
      isInitiallyVisible: true,
      Component: uiLib.LinkCell,
      width: uiLib.TABLE_COLUMN_WIDTH.XS
    },
    {
      title: 'Topics',
      dataKey: productServicesConstants.PRODUCT_SERVICES_ATTRIBUTES.TOPICS,
      sortKey: productServicesConstants.PRODUCT_SERVICES_ATTRIBUTES.TOPICS,
      isDynamic: true,
      isInitiallyVisible: true,
      Component: uiLib.LinkCell,
      width: uiLib.TABLE_COLUMN_WIDTH.XS
    }
  ];

  return {
    columns
  };
};
