import { createSlice } from '@reduxjs/toolkit';
import { getSpiderRunReports } from './spiderRunReports.actions';
import * as constants from './spiderRunReports.constants';

export const INITIAL_STATE = { count: 0, items: [], isFetching: false };

export const { actions, reducer } = createSlice({
  name: constants.REDUCER_NAME,
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: {
    [getSpiderRunReports.pending]: state => {
      state.isFetching = true;
    },
    [getSpiderRunReports.fulfilled]: (state, action) => {
      const { items, count } = action.payload;

      state.isFetching = false;
      state.items = items;
      state.count = count;
    },
    [getSpiderRunReports.rejected]: state => {
      state.isFetching = false;
    }
  }
});
