import type { MetaConceptStatusListConfig } from './useMetaConceptStatusListConfig.types';

import * as constants from '../MetaConceptStatus.constants';
import * as uiLib from '@compliance.ai/web-components';

import { useMemo } from 'react';

export const useMetaConceptStatusListConfig = (): MetaConceptStatusListConfig => {
  const options: MetaConceptStatusListConfig['options'] = useMemo(
    () => [
      {
        label: (
          <div className="sources_status_status-block_option">
            <uiLib.IconCheck />
            <uiLib.Typography>Activate</uiLib.Typography>
          </div>
        ),
        value: constants.META_CONCEPT_CREATION_VALUES.ACTIVE
      },
      {
        label: (
          <div className="sources_status_status-block_option">
            <uiLib.IconWarning />
            <uiLib.Typography>Deactivate</uiLib.Typography>
          </div>
        ),
        value: constants.META_CONCEPT_CREATION_VALUES.DEACTIVATED
      }
    ],
    []
  );

  return {
    options
  };
};
