import { setNotification, NOTIFICATION_TYPES } from 'admin/components/Notification';
import {
  api_getMetaConcepts,
  api_putMetaConcepts,
  api_postMetaConcepts,
  api_deleteMetaConcepts
} from './metaConcepts.api';
import {
  startLoad,
  finishLoad,
  setMetaConcept,
  createMetaConcept,
  updateMetaConcept,
  deleteMetaConcept
} from './metaConcepts.reducer';
import { initialState, setNewsSource } from '../sources/newsSources/newsSources.reducer';

const SUCCESS = {
  metaConceptCreated: 'Meta Concept was successfully created',
  metaConceptUpdated: 'Meta Concept was successfully updated',
  metaConceptDeleted: 'Meta Concept was successfully deleted'
};

export const getMetaConcepts = params => dispatch => {
  dispatch(startLoad());
  return api_getMetaConcepts(params)
    .then(res => {
      dispatch(setMetaConcept(res));
      dispatch(finishLoad());
    })
    .catch(res => dispatch(finishLoad()));
};

export const createMetaConcepts = params => dispatch => {
  dispatch(startLoad());
  return api_postMetaConcepts(params)
    .then(res => {
      setNotification({
        message: { text: SUCCESS.metaConceptCreated, title: 'Success' },
        type: NOTIFICATION_TYPES.SUCCESS
      });
      dispatch(createMetaConcept(res));
      dispatch(finishLoad());
      return res;
    })
    .catch(res => dispatch(finishLoad()));
};

export const updateMetaConcepts = params => dispatch => {
  dispatch(startLoad());
  return api_putMetaConcepts(params)
    .then(res => {
      setNotification({
        message: { text: SUCCESS.metaConceptUpdated, title: 'Success' },
        type: NOTIFICATION_TYPES.SUCCESS
      });
      dispatch(updateMetaConcept(res));
      dispatch(finishLoad());
      return res;
    })
    .catch(res => dispatch(finishLoad()));
};

export const removeMetaConcepts = params => dispatch => {
  dispatch(startLoad());
  return api_deleteMetaConcepts(params)
    .then(res => {
      setNotification({
        message: { text: SUCCESS.metaConceptDeleted, title: 'Success' },
        type: NOTIFICATION_TYPES.SUCCESS
      });
      dispatch(deleteMetaConcept(params));
      dispatch(finishLoad());
      return res;
    })
    .catch(res => dispatch(finishLoad()));
};

export const clearMetaConcepts = () => dispatch => {
  dispatch(finishLoad());
  return dispatch(setMetaConcept(initialState));
};
