import type { MomentInput } from 'moment';
import type * as metaConceptsDataAPi from 'shared/features/metaConcepts/metaConcepts.apiV2';

import * as uiLib from '@compliance.ai/web-components';
import * as metaConceptsConstants from 'admin/constants/metaConcepts';
import * as metaConceptsDataConstants from 'shared/features/metaConcepts/metaConcepts.constants';

export const getLocalDateEnd = (date?: MomentInput): Date => {
  const startOfNextDay = uiLib.getDateAfterDate({
    date,
    amount: 1,
    unitOfTime: uiLib.DATE_UNITS.DAY
  });

  return uiLib.getDateBeforeDate({
    date: startOfNextDay,
    amount: 1,
    unitOfTime: uiLib.DATE_UNITS.MILLISECONDS
  }) as Date;
};

export const getToday = () =>
  uiLib.getStartOfDate(new Date(), uiLib.DATE_UNITS.DAY, {
    passedTimezone: uiLib.getLocalTimezone(),
    shouldKeepLocalTime: false
  });

export const formatStartDate = (date?: MomentInput) => {
  return date
    ? uiLib.formatDate(date, {
        format: uiLib.DATE_FORMATS.API_DATE_TIME_WITH_T,
        passedTimezone: uiLib.getLocalTimezone(),
        timezone: uiLib.TIMEZONES.UTC
      })
    : null;
};

export const formatEndDate = (date?: MomentInput) => {
  if (date === null) {
    return null;
  }

  /**
   * getLocalDateEnd tries to solve issue with filtering documents by public_date,
   * if end date is before 12:00AM of the day document was published, api doesn't return it,
   * solution is to increase the end date up to 23:59:59 for all filtering requests
   */
  const endDate = getLocalDateEnd(date);

  return uiLib.getEndOfDate(endDate, uiLib.DATE_UNITS.DAY, {
    format: uiLib.DATE_FORMATS.API_DATE_TIME_WITH_T,
    passedTimezone: uiLib.getLocalTimezone(),
    timezone: uiLib.TIMEZONES.UTC
  });
};

export const formatDateWithModifiers =
  ({
    startDateQueryParamKey,
    endDateQueryParamKey
  }: {
    startDateQueryParamKey: string;
    endDateQueryParamKey: string;
  }) =>
  (filterValue: uiLib.FilterValue): Record<string, unknown> => {
    const value = filterValue as uiLib.DatePickerWithModifiersProps['value'];

    switch (value?.modifier) {
      case uiLib.DATE_MODIFIERS.BETWEEN: {
        return {
          [startDateQueryParamKey]: formatStartDate(value?.dateA),
          [endDateQueryParamKey]: formatEndDate(value?.dateB)
        };
      }

      case uiLib.DATE_MODIFIERS.ON: {
        return {
          [startDateQueryParamKey]: formatStartDate(value?.dateA),
          [endDateQueryParamKey]: formatEndDate(value?.dateA)
        };
      }

      case uiLib.DATE_MODIFIERS.ON_OR_BEFORE: {
        return {
          [endDateQueryParamKey]: formatEndDate(value?.dateA)
        };
      }

      case uiLib.DATE_MODIFIERS.ON_OR_AFTER: {
        return {
          [startDateQueryParamKey]: formatStartDate(value?.dateA)
        };
      }

      case uiLib.DATE_MODIFIERS.TODAY: {
        const today = getToday();
        return {
          [startDateQueryParamKey]: formatStartDate(today),
          [endDateQueryParamKey]: formatEndDate(today)
        };
      }

      case uiLib.DATE_MODIFIERS.YESTERDAY: {
        const today = getToday();
        const date = uiLib.getDateBeforeDate({
          date: today,
          amount: 1,
          unitOfTime: uiLib.DATE_UNITS.DAY
        });

        return {
          [startDateQueryParamKey]: formatStartDate(date instanceof Date ? date : null),
          [endDateQueryParamKey]: formatEndDate(date instanceof Date ? date : null)
        };
      }

      case uiLib.DATE_MODIFIERS.TOMORROW: {
        const today = getToday();
        const date = uiLib.getDateAfterDate({
          date: today,
          amount: 1,
          unitOfTime: uiLib.DATE_UNITS.DAY
        });

        return {
          [startDateQueryParamKey]: formatStartDate(date instanceof Date ? date : null),
          [endDateQueryParamKey]: formatEndDate(date instanceof Date ? date : null)
        };
      }

      default: {
        return {};
      }
    }
  };

export const formatMultiSelectValues =
  ({ queryParamKey }: { queryParamKey: string }) =>
  (filterValue: uiLib.FilterValue) => {
    const values = filterValue as uiLib.SelectOption[];

    if (Array.isArray(values) && values.length) {
      return {
        [queryParamKey]: values.map(({ value }) => value)
      };
    }

    return {};
  };

export const META_CONCEPTS_FILTER_VALUES_PARSERS: Record<
  string,
  (value: uiLib.FilterValue) => Parameters<typeof metaConceptsDataAPi.fetchMetaConceptsData>[0]
> = {
  [metaConceptsConstants.META_CONCEPTS_ATTRIBUTES.ID]: formatMultiSelectValues({
    queryParamKey: metaConceptsDataConstants.META_CONCEPTS_DATA_FETCH_QUERY_PARAMS.ID
  }),
  [metaConceptsConstants.META_CONCEPTS_ATTRIBUTES.CREATED_AT]: formatDateWithModifiers({
    startDateQueryParamKey:
      metaConceptsDataConstants.META_CONCEPTS_DATA_FETCH_QUERY_PARAMS.CREATED_AT_START,
    endDateQueryParamKey:
      metaConceptsDataConstants.META_CONCEPTS_DATA_FETCH_QUERY_PARAMS.CREATED_AT_END
  }),
  [metaConceptsConstants.META_CONCEPTS_ATTRIBUTES.UPDATED_AT]: formatDateWithModifiers({
    startDateQueryParamKey:
      metaConceptsDataConstants.META_CONCEPTS_DATA_FETCH_QUERY_PARAMS.UPDATED_AT_START,
    endDateQueryParamKey:
      metaConceptsDataConstants.META_CONCEPTS_DATA_FETCH_QUERY_PARAMS.UPDATED_AT_END
  })
};

export const formatMetaConceptsQueryParams = (
  values: uiLib.FiltersProps['values'],
  searchValue: string
): Record<string, unknown> => {
  return Object.entries(values).reduce(
    (formattedQueryParams, [filterKey, filterValue]) => {
      return {
        ...formattedQueryParams,
        ...META_CONCEPTS_FILTER_VALUES_PARSERS[filterKey](filterValue)
      };
    },
    {
      [metaConceptsDataConstants.META_CONCEPTS_DATA_FETCH_QUERY_PARAMS.MULTI_INPUT]: searchValue
    } as Record<string, unknown>
  );
};
