import { useEffect } from 'react';
import type { ProductServiceDetailsHandlers } from './useProductServiceDetailsHandlers.types';

export const useProductServiceDetailsLifecycle = ({
  onCalculateProductServiceDetails
}: {
  onCalculateProductServiceDetails: ProductServiceDetailsHandlers['handleCalculateProductService'];
}): void => {
  useEffect(onCalculateProductServiceDetails, [onCalculateProductServiceDetails]);
};
