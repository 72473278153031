import { fetch, BASE_API_OPTIONS } from 'fetch';
import * as apiTypes from './metaConcepts.api.types';

export const api_getMetaConcepts: apiTypes.GetMetaConcepts = params => {
  return fetch({
    url: `/meta_concepts`,
    dataType: 'MetaConcept',
    method: 'GET',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
};
export const api_postMetaConcepts: apiTypes.AddMetaConcept = params => {
  return fetch({
    url: `/meta_concepts`,
    dataType: 'MetaConcept',
    method: 'POST',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
};

export const api_putMetaConcepts: apiTypes.ModifyMetaConcept = (id, params) => {
  return fetch({
    url: `/meta_concepts/${id}`,
    dataType: 'MetaConcept',
    method: 'PATCH',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
};

export const api_deleteMetaConcepts: apiTypes.DeleteMetaConcept = id => {
  return fetch({
    url: `/meta_concepts/${id}`,
    dataType: 'MetaConcept',
    method: 'DELETE',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA
  });
};
