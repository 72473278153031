import type { MetaConceptEditHandlers } from '../../hooks/useMetaConceptPageHandlers.types';
import type { MetaConceptDetailsNormalized } from '../MetaConceptDetails/MetaConceptDetails.types';

import * as uiLib from '@compliance.ai/web-components';

import {
  useMetaConceptStatusData,
  useMetaConceptStatusHandlers,
  useMetaConceptStatusListConfig
} from './hooks';

export const MetaConceptStatus = ({
  metaConceptDetails,
  onChangeStatus
}: {
  metaConceptDetails: MetaConceptDetailsNormalized;
  onChangeStatus: MetaConceptEditHandlers['handleUpdateMetaConceptStatus'];
}) => {
  const { localActions, localState, formattedData } = useMetaConceptStatusData({
    metaConceptDetails: metaConceptDetails
  });

  const handlers = useMetaConceptStatusHandlers({
    props: {
      onChangeStatus
    },
    localActions
  });
  const listConfig = useMetaConceptStatusListConfig();
  return (
    <div className="meta-concepts-navigation_status">
      <div className="meta-concepts-navigation_status_date-block">
        <uiLib.Typography isItalic>{`Created ${formattedData.createdDate}`}</uiLib.Typography>
        <uiLib.Typography isItalic>{`Updated ${formattedData.updatedDate}`}</uiLib.Typography>
      </div>
      <div className="meta-concepts-navigation_status_status-block">
        <uiLib.Typography>Status</uiLib.Typography>
        <uiLib.Dropdown
          className="meta-concepts-navigation_status_status-block_dropdown"
          isOpen={localState.isOpen}
          onClose={handlers.handleStatusDropdownToggle(false)}
          triggerContent={
            <uiLib.Button
              className="meta-concepts-navigation_status_status-block_dropdown_trigger"
              onClick={handlers.handleStatusDropdownToggle(true)}
              type={
                metaConceptDetails?.is_active
                  ? uiLib.BUTTON_TYPES.SUCCESS
                  : uiLib.BUTTON_TYPES.DELETE
              }
            >
              {metaConceptDetails?.is_active ? <uiLib.IconCheck /> : <uiLib.IconWarning />}
              <uiLib.Typography>
                {metaConceptDetails?.is_active ? 'Activated' : 'Deactivated'}
              </uiLib.Typography>
              <uiLib.IconEdit />
            </uiLib.Button>
          }
          dropdownContent={
            <uiLib.List
              selectedOptions={[]}
              onOptionClick={handlers.handleListOptionClick}
              options={listConfig.options}
            />
          }
        />
      </div>
      <div className="meta-concepts-navigation_status_icon-block">
        <uiLib.Button onClick={handlers.handleGoBack} type={uiLib.BUTTON_TYPES.SECONDARY}>
          <uiLib.IconClose fontSize="large" />
        </uiLib.Button>
      </div>
    </div>
  );
};
