import React from 'react';

import * as uiLib from '@compliance.ai/web-components';

import ROUTES from 'admin/constants/routes';
import Iframe from 'admin/containers/Iframe';
import AgencyLandingPage from 'admin/containers/AgencyLandingPage/AgencyLandingPage';
import Dashboard from 'admin/containers/Dashboard';
import AnnotationTool from 'admin/containers/AnnotationTool/AnnotationTool';
import ContributorTool from 'admin/containers/ContributorTool';
import ReduxSlotTool from 'admin/containers/SlotTool';
import CreateOrganizations from 'admin/containers/CreateOrganizations';
import EditOrganizations from 'admin/containers/EditOrganizations/EditOrganizations';
import AdminRolesTool from 'admin/containers/AdminRoles/AdminRolesTool';
import CommandsTools from 'admin/containers/CommandTools/CommandsTools';
import CommandRquestsTools from 'admin/containers/CommandRequests';
import IncompleteDocTool from 'admin/containers/IncompleteDocTool/IncompleteDocTool';
import DocumentUpdate from 'admin/containers/DocumentUpdate/DocumentUpdate';
import AdminTool from 'admin/containers/DocumentAdminTool/AdminTool';
import CrawlMonitorTool from 'admin/containers/CrawlMonitorTool/CrawlMonitorTool';
import UserCreatedDocuments from 'admin/containers/UserCreatedDocuments/UserCreatedDocuments';
import ContentManagement from 'admin/containers/ContentManagement/ContentManagement';
import ReduxDocumentMetaCategories from 'admin/containers/DocumentMetaCategories/DocumentMetaCategories';
import ReduxDocumentCategories from 'admin/containers/DocumentCategories/DocumentCategories';
import JurisdictionAdminToolTools from 'admin/containers/JurisdictionTool/JurisdictionTool';
import CrawlerPerformanceTool from 'admin/containers/CrawlerPerformance/CrawlerPerformanceTool';
import CollectionTool from 'admin/containers/CollectionTool/CollectionTool';
import Concepts from 'admin/containers/ContentManagement/Concepts/Concepts';
import MetaConcepts from 'admin/containers/ContentManagement/MetaConcepts/MetaConcepts';
import ProductServices from 'admin/containers/ContentManagement/ProductServices/ProductServices';
import JurisdictionAnalyzerConcepts from 'admin/containers/JurisdictionAnalyzerConcepts/JurisdictionAnalyzerConcepts';
import Metrics from 'admin/containers/Metrics/Metrics';
import ReduxAnnotationTasks from 'admin/containers/AnnotationTasks/AnnotationTasks';
import AnnotationTaskTopicGroups from 'admin/containers/AnnotationTaskTopicGroups/AnnotationTaskTopicGroups';
import AggregatedAnnotations from 'admin/containers/AggregatedAnnotations/AggregatedAnnotations';
import Topics from 'admin/containers/Topics/Topics';
import TermSamplingGroups from 'admin/containers/TermSamplingGroups/TermSamplingGroups';
import TopicsStats from 'admin/containers/TopicsStats';
import ModelValidations from 'admin/containers/ModelValidationTable';
import OnboardingResults from 'admin/containers/OnbaordingResults/OnboardingResults';
import SkippedDocuments from 'admin/containers/SkippedDocuments/SkippedDocuments';
import Users from 'admin/containers/Users/Users';
import Organizations from 'admin/containers/Organizations';
import ManageOrgTeams from 'admin/containers/ManageOrgTeams/ManageOrgTeams';
import Subscriptions from 'admin/containers/Subscriptions/Subscriptions';
import Invite from 'admin/containers/EditOrganizations/Invite';
import MarketingCampaigns from 'admin/containers/marketingCampaigns';
import AppNotifications from 'admin/containers/AppNotifications/AppNotifications.index';
import ContributorStatistics from 'admin/containers/ContributorStatistics';
import VolumeStatistics from 'admin/containers/VolumeStatistics';
import GoogleAnalyticsReporting from 'admin/containers/GoogleAnalyticsReporting';
import Charts from 'admin/containers/Charts';
import Publications from 'admin/containers/Publications/Publications';
import CustomSettings from 'admin/containers/CustomSettings';
import { Navigate, useRoutes } from 'react-router';
import { appUrl } from 'shared/config';
import auth from 'shared/utils/auth';
import CrawlerSettings from 'admin/containers/CrawlerPage/CrawlerSettings';
import AgencySettings from 'admin/containers/AgencyPage/AgencySettings';
import DocumentSettings from 'admin/containers/DocumentPage/DocumentSettingsNavigation';
import SourcePage from 'admin/containers/SourcePage/SourcePage';
import ConceptPage from 'admin/containers/ConceptPage/ConceptPage';
import MetaConceptPage from 'admin/containers/MetaConceptPage/MetaConceptPage';
import { Login } from 'admin/containers/Login';
import ProductServicePage from 'admin/containers/ProductServicePage/ProductServicePage';

const DocumentManagement = uiLib.loadLazyWithRetry(
  () => import('admin/containers/DocumentManagement')
);

export const ROUTES_CONFIG: Parameters<typeof useRoutes>[0] = [
  { path: ROUTES.login, element: <Login /> },

  { path: ROUTES.iframe, element: <Iframe /> },
  { path: ROUTES.agencyLandingPage, element: <AgencyLandingPage /> },
  { path: ROUTES.dashboard, element: <Dashboard /> },

  { path: ROUTES.annotationtool, element: <AnnotationTool /> },
  { path: ROUTES.contributortool, element: <ContributorTool /> },
  { path: ROUTES.slotTool, element: <ReduxSlotTool /> },
  { path: ROUTES.createOrganizations, element: <CreateOrganizations /> },
  { path: ROUTES.editOrganizations, element: <EditOrganizations /> },

  // Admin Settings
  { path: ROUTES.adminRoles, element: <AdminRolesTool /> },
  // Dev Ops
  { path: ROUTES.commandsTools, element: <CommandsTools /> },
  { path: ROUTES.commandRequestTools, element: <CommandRquestsTools /> },
  // Document QA
  { path: ROUTES.incompleteDocTool, element: <IncompleteDocTool /> },
  { path: ROUTES.documentUpdate, element: <DocumentUpdate /> },
  // Document Data
  { path: ROUTES.adminTool, element: <AdminTool /> },
  { path: ROUTES.crawlerMonitoring, element: <CrawlMonitorTool /> },
  {
    path: ROUTES.documents,
    element: (
      <uiLib.LazyRouteWrapper>
        <DocumentManagement />
      </uiLib.LazyRouteWrapper>
    )
  },
  { path: ROUTES.userCreatedDocuments, element: <UserCreatedDocuments /> },
  { path: ROUTES.agenciesAdminTool, element: <ContentManagement /> }, //TODO delete
  { path: ROUTES.contentManagement, element: <ContentManagement /> },
  { path: ROUTES.documentMetaCategories, element: <ReduxDocumentMetaCategories /> },
  { path: ROUTES.documentCategories, element: <ReduxDocumentCategories /> },
  { path: ROUTES.jurisdictionAdminTools, element: <JurisdictionAdminToolTools /> },
  { path: ROUTES.crawlerPerformance, element: <CrawlerPerformanceTool /> },
  { path: ROUTES.collectionTool, element: <CollectionTool /> },
  { path: ROUTES.conceptTool, element: <Concepts /> },
  { path: ROUTES.metaConceptTool, element: <MetaConcepts /> },
  { path: ROUTES.productServiceTool, element: <ProductServices /> },
  { path: `${ROUTES.crawlerTool}/:id`, element: <CrawlerSettings /> },
  { path: `${ROUTES.agenciesAdminTool}/:id`, element: <AgencySettings /> },
  { path: `${ROUTES.documents}/:id`, element: <DocumentSettings /> },
  { path: `${ROUTES.sources}/:id`, element: <SourcePage /> },
  { path: `${ROUTES.conceptTool}/:id`, element: <ConceptPage /> },
  { path: `${ROUTES.metaConceptTool}/:id`, element: <MetaConceptPage /> },
  { path: `${ROUTES.productServiceTool}/:id`, element: <ProductServicePage /> },
  // Jurisdiction Analyzer
  { path: ROUTES.concepts, element: <JurisdictionAnalyzerConcepts /> },
  { path: ROUTES.metrics, element: <Metrics /> },
  // Topics
  { path: ROUTES.annotationTasks, element: <ReduxAnnotationTasks /> },
  { path: ROUTES.annotationTaskTopicGroups, element: <AnnotationTaskTopicGroups /> },
  { path: ROUTES.aggregatedAnnotations, element: <AggregatedAnnotations /> },
  { path: ROUTES.topics, element: <Topics /> },
  { path: ROUTES.termSamplingGroups, element: <TermSamplingGroups /> },
  { path: ROUTES.topicsStats, element: <TopicsStats /> },
  { path: ROUTES.topicAnnotationModelValidations, element: <ModelValidations /> },
  { path: ROUTES.onboardingResults, element: <OnboardingResults /> },
  { path: ROUTES.skippedDocuments, element: <SkippedDocuments /> },
  // User Management
  { path: ROUTES.users, element: <Users /> },
  // Inner properties of this component will be added by App
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  { path: ROUTES.organizations, element: <Organizations /> },
  { path: ROUTES.manageOrgTeams, element: <ManageOrgTeams /> },
  { path: ROUTES.subscriptions, element: <Subscriptions /> },
  { path: ROUTES.invite, element: <Invite /> },
  { path: ROUTES.marketing, element: <MarketingCampaigns /> },
  { path: ROUTES.appNotifications, element: <AppNotifications /> },
  { path: ROUTES.contributorStatistics, element: <ContributorStatistics /> },
  // Visualization/Prototypes
  { path: ROUTES.volumeStatistics, element: <VolumeStatistics /> },
  { path: ROUTES.googleAnalyticsReporting, element: <GoogleAnalyticsReporting /> },
  { path: ROUTES.charts, element: <Charts /> },
  { path: ROUTES.publications, element: <Publications /> },
  // Custom Settings
  { path: ROUTES.customSettings, element: <CustomSettings /> },
  {
    path: ROUTES.proContributor,
    element: (
      <Navigate
        to={`${appUrl}dashboard?is_contributor=true&token=${encodeURIComponent(auth.getToken())}`}
      />
    )
  },
  // Fallback
  { path: '*', element: <Navigate to={ROUTES.dashboard} /> }
];
