import type { MetaConceptsData } from './useMetaConceptsData.types';

import * as uiLib from '@compliance.ai/web-components';
import * as constants from '../MetaConcepts.constants';

import { useMemo, useState } from 'react';

export const useMetaConceptsData = (): MetaConceptsData => {
  const [filters, setFilters] = useState<MetaConceptsData['localState']['filters']>({});
  const [tableParams, setTableParams] = useState<MetaConceptsData['localState']['tableParams']>({
    ...uiLib.DEFAULT_TABLE_PARAMS,
    [uiLib.TABLE_PARAMS.LIMIT]: constants.TABLE_FETCH_REQUEST_LIMIT
  });
  const [reloadTrigger, setReloadTrigger] =
    useState<MetaConceptsData['localState']['reloadTrigger']>(false);

  const localState: MetaConceptsData['localState'] = {
    filters,
    tableParams,
    reloadTrigger
  };

  const localActions: MetaConceptsData['localActions'] = useMemo(
    () => ({
      setFilters,
      setTableParams,
      setReloadTrigger
    }),
    []
  );

  return {
    localState,
    localActions
  };
};
