export enum META_CONCEPTS_DATA_FETCH_QUERY_PARAMS {
  LIMIT = 'limit',
  OFFSET = 'offset',
  SORT = 'sort',
  ID = 'id',
  NAME = 'name',
  MULTI_INPUT = 'multi_input',
  CREATED_AT_START = 'created_at_start',
  CREATED_AT_END = 'created_at_end',
  UPDATED_AT_START = 'updated_at_start',
  UPDATED_AT_END = 'updated_at_end'
}
