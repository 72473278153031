import type { GlobalState } from 'shared/reducers';
import type { MetaConceptPageData } from './useMetaConceptPageData.types';

import * as metaConceptsSelectors from 'shared/features/metaConcepts/metaConcept.selectors';

import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

export const useMetaConceptPageData = (): MetaConceptPageData => {
  const { id } = useParams();

  const reduxState: MetaConceptPageData['reduxState'] = useSelector<
    GlobalState,
    MetaConceptPageData['reduxState']
  >(state => ({
    metaConcept: metaConceptsSelectors.getFirstMetaConcept(state)
  }));

  const formattedData: MetaConceptPageData['formattedData'] = useMemo(
    () => ({
      metaConceptId: id,
      isActive: reduxState.metaConcept?.is_active
    }),
    [id, reduxState.metaConcept?.is_active]
  );

  return {
    reduxState,
    formattedData
  };
};
