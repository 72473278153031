import type { ProductServicePageData } from './useProductServicePageData.types';
import type { ProductServiceEditHandlers } from './useProductServicePageHandlers.types';

import { useProductServicesReduxActions } from 'shared/features/productServices/hooks';
import { useCallback } from 'react';

export const useProductServicePageHandlers = ({
  formattedData
}: {
  formattedData: ProductServicePageData['formattedData'];
}): ProductServiceEditHandlers => {
  const productServicesReduxActions = useProductServicesReduxActions();

  const handleLoadproductService: ProductServiceEditHandlers['handleLoadProductService'] =
    useCallback(() => {
      productServicesReduxActions.clearProductServices();
      if (!isNaN(Number(formattedData.productServiceId))) {
        productServicesReduxActions.fetchProductService({ id: formattedData.productServiceId });
      }
    }, [formattedData.productServiceId, productServicesReduxActions]);

  const handleUpdateProductServiceStatus: ProductServiceEditHandlers['handleUpdateProductServiceStatus'] =
    isActive => {
      productServicesReduxActions.updateProductService({
        id: formattedData.productServiceId,
        active: isActive
      });
    };

  return {
    handleLoadProductService: handleLoadproductService,
    handleUpdateProductServiceStatus: handleUpdateProductServiceStatus
  };
};
