import { fetch, BASE_API_OPTIONS } from 'fetch';
import type * as apiTypes from './productServices.api.types';

export const api_getProductServices: apiTypes.GetProductServices = params => {
  return fetch({
    url: `/product_services`,
    dataType: 'ProductService',
    method: 'GET',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
};
export const api_postProductServices: apiTypes.AddProductService = params => {
  return fetch({
    url: `/product_services`,
    dataType: 'ProductService',
    method: 'POST',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
};

export const api_putProductServices: apiTypes.ModifyProductService = (id, params) => {
  return fetch({
    url: `/product_services/${id}`,
    dataType: 'ProductService',
    method: 'PATCH',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
};

export const api_deleteProductServices: apiTypes.DeleteProductService = id => {
  return fetch({
    url: `/product_services/${id}`,
    dataType: 'ProductService',
    method: 'DELETE',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA
  });
};
