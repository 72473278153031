import type { ProductServiceDetailsData } from './useProductServiceDetailsData.types';
import type { ProductServiceDetailsHandlers } from './useProductServiceDetailsHandlers.types';

import ROUTES from 'admin/constants/routes';
import { NOTIFICATION_TYPES, setNotification } from 'admin/components/Notification';
import {
  CONTENT_MANAGEMENT_TAB_KEYS,
  CONTENT_MANAGEMENT_TAB_QUERY_PARAM
} from 'admin/containers/ContentManagement/ContentManagement.constants';
import * as helpers from '../ProductServiceDetails.helper';
import * as errorUtils from 'shared/utils/errors';

import { useProductServicesApi } from 'shared/features/productServices/hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { useHistory } from 'admin/utils/hooks';

export const useProductServiceDetailsHandlers = ({
  localActions,
  localState,
  formattedData,
  reduxState
}: {
  localActions: ProductServiceDetailsData['localActions'];
  localState: ProductServiceDetailsData['localState'];
  formattedData: ProductServiceDetailsData['formattedData'];
  reduxState: ProductServiceDetailsData['reduxState'];
}): ProductServiceDetailsHandlers => {
  const history = useHistory();
  const location = useLocation();
  const navigate = useNavigate();
  const productServicesApi = useProductServicesApi();

  const handleCalculateProductService: ProductServiceDetailsHandlers['handleCalculateProductService'] =
    useCallback(() => {
      const formatProductServiceDetails = helpers.fromRawInfoToDetails(reduxState.productService);
      localActions.setProductServiceDetails(formatProductServiceDetails);
    }, [localActions, reduxState.productService]);

  const handleToggleEditMode: ProductServiceDetailsHandlers['handleToggleEditMode'] = () => {
    if (localState.editMode) {
      handleCalculateProductService();
    }
    localActions.setEditMode(!localState.editMode);
  };

  const handleChanges: ProductServiceDetailsHandlers['handleChanges'] = (name, value) => {
    return localActions.setProductServiceDetails(productServiceDetails => ({
      ...productServiceDetails,
      [name]: value
    }));
  };

  const handleSaveChanges: ProductServiceDetailsHandlers['handleSaveChanges'] = async () => {
    const { productServiceDetails: productServiceInfo, errors } = helpers.fromDetailsToRawInfo(
      localState.productServiceDetails,
      reduxState.productService
    );
    if (errors.length) {
      localActions.setErrorFields(errors.map(err => err?.title));
      errors.forEach(error => setNotification({ message: error, type: NOTIFICATION_TYPES.ERROR }));
      return;
    }
    handleToggleEditMode();
    if (!isNaN(Number(formattedData.productServiceId))) {
      try {
        const responseData = await productServicesApi.modifyProductService(
          Number(formattedData.productServiceId),
          {
            ...productServiceInfo
          }
        );

        localActions.setProductServiceDetails({
          ...localState.productServiceDetails,
          ...responseData
        });
        if (responseData.id) {
          localActions.setErrorFields([]);
        }
      } catch (e) {
        // here we used to call errorUtils to do logError(e as Error);
        // that led to double notification along the error handling chain
      }
    } else {
      try {
        const responseData = await productServicesApi.addProductService(productServiceInfo);
        localActions.setProductServiceDetails({
          ...localState.productServiceDetails,
          ...responseData
        });
        const responseDataId = responseData?.id;
        if (responseDataId) {
          localActions.setErrorFields([]);
          navigate(`${ROUTES.productServiceTool}/${responseDataId}`);
        }
      } catch (e) {
        // here we used to call errorUtils to do logError(e as Error);
        // that led to double notification along the error handling chain
      }
    }
  };

  const handleOnDelete: ProductServiceDetailsHandlers['handleOnDelete'] = async () => {
    await productServicesApi.deleteProductService(Number(formattedData.productServiceId));
    navigate(
      `${ROUTES.contentManagement}?${CONTENT_MANAGEMENT_TAB_QUERY_PARAM}=${CONTENT_MANAGEMENT_TAB_KEYS.META_CONCEPTS}`,
      {
        ...location.state,
        relative: 'path'
      }
    );
  };

  const handleToggleConfirmationModal: ProductServiceDetailsHandlers['handleToggleConfirmationModal'] =
    () => {
      localActions.setIsOpen(!localState.isOpen);
    };

  const handleStatusDropdownToggle: ProductServiceDetailsHandlers['handleStatusDropdownToggle'] =
    isOpen => () => {
      localActions.setIsOpen(isOpen);
    };

  const handleGoBack: ProductServiceDetailsHandlers['handleGoBack'] = () => {
    navigate(`${ROUTES.contentManagement}?tab=product_services`, location.state);
  };

  return {
    handleStatusDropdownToggle,
    handleGoBack,
    handleCalculateProductService,
    handleToggleEditMode,
    handleChanges,
    handleSaveChanges,
    handleOnDelete,
    handleToggleConfirmationModal
  };
};
