import type * as apiTypes from './productServices.api.types';

import { fetch, BASE_API_OPTIONS } from 'fetch';

export const fetchProductServicesData: apiTypes.FetchProductServicesData = async params => {
  return fetch({
    url: `/product_services`,
    dataType: 'ProductService',
    method: 'GET',
    baseApi: BASE_API_OPTIONS.BASE_API_DATA,
    params
  });
};
