import type * as apiTypes from './productServices.api.types';

import * as uiLib from '@compliance.ai/web-components';

export const normalizeProductServicesResponse = (
  response: apiTypes.GetProductServicesResponse
): apiTypes.NormalizedProductServicesResponse => {
  return {
    count: response.count,
    labels: response.items.map(normalizeProductService)
  };
};

export const normalizeProductService = (
  productService: apiTypes.ProductServiceFromResponse
): apiTypes.NormalizedProductService => {
  return {
    id: productService.id,
    name: productService.name,
    createdAt: uiLib.isValidDate(productService.created_at) ? productService.created_at : '',
    userId: productService.user_id
  };
};
