import React, { FC } from 'react';

import * as uiLib from '@compliance.ai/web-components';
import * as constants from './ProductServices.constants';
import * as productServicesConstants from 'admin/constants/productServices';
import * as elements from './elements';
import * as errorUtils from 'shared/utils/errors';

import './_product-services.scss';

import {
  useProductServicesData,
  useProductServicesHandlers,
  useProductServicesTableConfig,
  useProductServicesSelector
} from './hooks';

const ProductServices: FC = () => {
  const { localState, localActions } = useProductServicesData();
  const handlers = useProductServicesHandlers({ localState, localActions });

  const tableConfig = useProductServicesTableConfig();

  const tableDataSelector = useProductServicesSelector();

  return (
    <uiLib.ErrorBoundary>
      <div className="product-services__container">
        <div className="product-services__filters">
          <elements.ProductServicesFilters onSubmit={handlers.handleFiltersSubmit} />
          <uiLib.Button onClick={handlers.handleCreateNewProductService}>
            Add Area of Focus
          </uiLib.Button>
        </div>
        <div className="product-services__table-wrapper">
          <uiLib.Table
            shouldShowTableOptions
            externalParams={localState.tableParams}
            rowKey={productServicesConstants.PRODUCT_SERVICES_ATTRIBUTES.ID}
            columns={tableConfig.columns}
            onDataFetch={handlers.handleProductServicesFetch}
            onParamsChange={localActions.setTableParams}
            reloadTrigger={localState.reloadTrigger}
            localStorageKey={constants.TABLE_LOCAL_STORAGE_KEY}
            shouldShowColumnsSettingsButton
            shouldShowFirstPageButton
            shouldShowLastPageButton
            maxAllowedRowsCount={constants.ALLOWED_SELECTED_PRODUCT_SERVICES_COUNT}
            rowsPerPageOptions={constants.ROWS_PER_PAGE_OPTIONS}
            initialRowsPerPage={constants.ROWS_PER_PAGE_OPTIONS[0]}
            queryOptions={{
              select: tableDataSelector,
              onError: errorUtils.logReactQueryError
            }}
            classNames={{
              container: 'product-services__table-outer-container',
              tableContainer: 'product-services__table-inner-container',
              toolbar: 'product-services__table-toolbar',
              headerCell: 'product-services__table-header-cell',
              selectedRowsCount: 'product-services__table-selected-rows-count'
            }}
          />
        </div>
      </div>
    </uiLib.ErrorBoundary>
  );
};

export default ProductServices;
