import { setNotification, NOTIFICATION_TYPES } from 'admin/components/Notification';
import {
  api_getProductServices,
  api_putProductServices,
  api_postProductServices,
  api_deleteProductServices
} from './productServices.api';
import {
  startLoad,
  finishLoad,
  setProductService,
  createProductService,
  updateProductService,
  deleteProductService
} from './productServices.reducer';

import { initialState } from './productServices.reducer';

const SUCCESS = {
  ProductServiceCreated: 'Product Service was successfully created',
  ProductServiceUpdated: 'Product Service was successfully updated',
  ProductServiceDeleted: 'Product Service was successfully deleted'
};

export const getProductServices = params => dispatch => {
  dispatch(startLoad());
  return api_getProductServices(params)
    .then(res => {
      dispatch(setProductService(res));
      dispatch(finishLoad());
    })
    .catch(res => dispatch(finishLoad()));
};

export const createProductServices = params => dispatch => {
  dispatch(startLoad());
  return api_postProductServices(params)
    .then(res => {
      setNotification({
        message: { text: SUCCESS.productServiceCreated, title: 'Success' },
        type: NOTIFICATION_TYPES.SUCCESS
      });
      dispatch(createProductService(res));
      dispatch(finishLoad());
      return res;
    })
    .catch(res => dispatch(finishLoad()));
};

export const updateProductServices = params => dispatch => {
  dispatch(startLoad());
  return api_putProductServices(params)
    .then(res => {
      setNotification({
        message: { text: SUCCESS.productServiceUpdated, title: 'Success' },
        type: NOTIFICATION_TYPES.SUCCESS
      });
      dispatch(updateProductService(res));
      dispatch(finishLoad());
      return res;
    })
    .catch(res => dispatch(finishLoad()));
};

export const removeProductServices = params => dispatch => {
  dispatch(startLoad());
  return api_deleteProductServices(params)
    .then(res => {
      setNotification({
        message: { text: SUCCESS.ProductServiceDeleted, title: 'Success' },
        type: NOTIFICATION_TYPES.SUCCESS
      });
      dispatch(deleteProductService(params));
      dispatch(finishLoad());
      return res;
    })
    .catch(res => dispatch(finishLoad()));
};

export const clearProductServices = () => dispatch => {
  dispatch(finishLoad());
  return dispatch(setProductService(initialState));
};
