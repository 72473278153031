export enum PRODUCT_SERVICES_ATTRIBUTES {
  ID = 'id',
  CREATED_AT = 'created_at',
  UPDATED_AT = 'updated_at',
  NAME = 'name',
  DESCRIPTION = 'description',
  IS_ACTIVE = 'is_active',
  BUSINESS_LINE = 'business_line',
  AGENCIES = 'agencies',
  CONCEPTS = 'concepts',
  NEWS_SOURCES = 'news_sources',
  TOPICS = 'topics'
}
