import type { ComponentProps } from 'react';

import * as uiLib from '@compliance.ai/web-components';
import * as categoryConstants from 'admin/constants/categories';
import * as constants from '../MetaConceptsFilters.constants';

export const useMetaConceptsFiltersConfig = (): ComponentProps<typeof uiLib.Filters>['config'] => [
  {
    key: categoryConstants.CATEGORY_ATTRIBUTES.CREATED_AT,
    label: 'Created At',
    type: uiLib.FILTER_TYPES.DATE_WITH_MODIFIERS,
    inputComponentProps: {
      whitelistedModifiers: constants.DATE_FILTER_ALLOWED_MODIFIERS
    }
  },
  {
    key: categoryConstants.CATEGORY_ATTRIBUTES.UPDATED_AT,
    label: 'Updated At',
    type: uiLib.FILTER_TYPES.DATE_WITH_MODIFIERS,
    inputComponentProps: {
      whitelistedModifiers: constants.DATE_FILTER_ALLOWED_MODIFIERS
    }
  }
];
