import type { ProductServiceDetailsNormalized } from '../../ProductServiceDetails/ProductServiceDetails.types';
import type { ProductServiceStatusData } from './useProductServiceStatusData.types';

import * as uiLib from '@compliance.ai/web-components';

import { useMemo, useState } from 'react';

export const useProductServiceStatusData = ({
  productServiceDetails
}: {
  productServiceDetails: ProductServiceDetailsNormalized;
}): ProductServiceStatusData => {
  const [isOpen, setIsOpen] = useState<ProductServiceStatusData['localState']['isOpen']>(false);

  const localState: ProductServiceStatusData['localState'] = {
    isOpen
  };

  const localActions: ProductServiceStatusData['localActions'] = useMemo(
    () => ({
      setIsOpen
    }),
    []
  );

  const formattedData: ProductServiceStatusData['formattedData'] = useMemo(
    () => ({
      createdDate: uiLib.formatDate(productServiceDetails?.created_at, {
        format: uiLib.DATE_FORMATS.API_DATE_TIME,
        passedTimezone: uiLib.getLocalTimezone(),
        timezone: uiLib.TIMEZONES.UTC
      }),
      updatedDate: uiLib.formatDate(productServiceDetails?.updated_at, {
        format: uiLib.DATE_FORMATS.API_DATE_TIME,
        passedTimezone: uiLib.getLocalTimezone(),
        timezone: uiLib.TIMEZONES.UTC
      })
    }),
    [productServiceDetails?.created_at, productServiceDetails?.updated_at]
  );

  return {
    localState,
    localActions,
    formattedData
  };
};
