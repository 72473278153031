import type { LogsLinkCellData } from './useLogsLinkCellData.types';
import type { LogsLinkCellHandlers } from './useLogsLinkCellHandlers.types';
import { useCallback } from 'react';
import { useSpiderRunReportsReduxActions } from 'shared/features/crawlerSettings/spiderRunReports/hooks';
import { useParams } from 'react-router-dom';

export const useLogsLinkCellHandlers = ({
  localActions,
  formattedData
}: {
  localActions: LogsLinkCellData['localActions'];
  formattedData: LogsLinkCellData['formattedData'];
}): LogsLinkCellHandlers => {
  const { id } = useParams();
  const spiderRunReportReduxActions = useSpiderRunReportsReduxActions();
  const requestProcessedLogsSpiderRunReport = useCallback(
    (extraProps = { exclude_types: ['dry_run'], parent_report_id: 0 }) => {
      spiderRunReportReduxActions.getSpiderRunReports({
        crawlId: id,
        params: {
          offset: 0,
          limit: 1,
          process_logs_report_id: formattedData.reportId,
          ...extraProps
        }
      });
    },
    [formattedData.reportId, id, spiderRunReportReduxActions]
  );
  const handleModalToggle: LogsLinkCellHandlers['handleModalToggle'] = isOpen => () => {
    if (isOpen) {
      requestProcessedLogsSpiderRunReport();
    }
    localActions.setIsModalOpen(isOpen);
  };

  return {
    handleModalToggle
  };
};
