import type { GlobalState } from 'shared/reducers';
import type { MetaConceptFromResponseV2 } from './metaConcepts.api.types';

import { createSelector } from 'reselect';

export const getMetaConceptsReducer = createSelector(
  (state: GlobalState) => state,
  (state: GlobalState) => state.metaConcepts
);

export const getMetaConcepts = createSelector(
  getMetaConceptsReducer,
  (currentMetaConceptsReducer): MetaConceptFromResponseV2[] => {
    return currentMetaConceptsReducer?.items;
  }
);

export const getFirstMetaConcept = createSelector(
  getMetaConcepts,
  (metaConcepts: MetaConceptFromResponseV2[]): MetaConceptFromResponseV2 => metaConcepts?.[0]
);
