import type { ProductServiceEditHandlers } from '../../hooks/useProductServicePageHandlers.types';
import type { ProductServiceDetailsNormalized } from '../ProductServiceDetails/ProductServiceDetails.types';

import * as uiLib from '@compliance.ai/web-components';

import {
  useProductServiceStatusData,
  useProductServiceStatusHandlers,
  useProductServiceStatusListConfig
} from './hooks';

export const ProductServiceStatus = ({
  productServiceDetails,
  onChangeStatus
}: {
  productServiceDetails: ProductServiceDetailsNormalized;
  onChangeStatus: ProductServiceEditHandlers['handleUpdateProductServiceStatus'];
}) => {
  const { localActions, localState, formattedData } = useProductServiceStatusData({
    productServiceDetails: productServiceDetails
  });

  const handlers = useProductServiceStatusHandlers({
    props: {
      onChangeStatus
    },
    localActions
  });
  const listConfig = useProductServiceStatusListConfig();
  return (
    <div className="product-services-navigation_status">
      <div className="product-services-navigation_status_date-block">
        <uiLib.Typography isItalic>{`Created ${formattedData.createdDate}`}</uiLib.Typography>
        <uiLib.Typography isItalic>{`Updated ${formattedData.updatedDate}`}</uiLib.Typography>
      </div>
      <div className="product-services-navigation_status_status-block">
        <uiLib.Typography>Status</uiLib.Typography>
        <uiLib.Dropdown
          className="product-services-navigation_status_status-block_dropdown"
          isOpen={localState.isOpen}
          onClose={handlers.handleStatusDropdownToggle(false)}
          triggerContent={
            <uiLib.Button
              className="product-services-navigation_status_status-block_dropdown_trigger"
              onClick={handlers.handleStatusDropdownToggle(true)}
              type={
                productServiceDetails?.is_active
                  ? uiLib.BUTTON_TYPES.SUCCESS
                  : uiLib.BUTTON_TYPES.DELETE
              }
            >
              {productServiceDetails?.is_active ? <uiLib.IconCheck /> : <uiLib.IconWarning />}
              <uiLib.Typography>
                {productServiceDetails?.is_active ? 'Activated' : 'Deactivated'}
              </uiLib.Typography>
              <uiLib.IconEdit />
            </uiLib.Button>
          }
          dropdownContent={
            <uiLib.List
              selectedOptions={[]}
              onOptionClick={handlers.handleListOptionClick}
              options={listConfig.options}
            />
          }
        />
      </div>
      <div className="product-services-navigation_status_icon-block">
        <uiLib.Button onClick={handlers.handleGoBack} type={uiLib.BUTTON_TYPES.SECONDARY}>
          <uiLib.IconClose fontSize="large" />
        </uiLib.Button>
      </div>
    </div>
  );
};
