import type { MetaConceptsApi } from './useMetaConceptsApi.types';

import * as api from '../metaConcepts.api';
import * as helpers from '../metaConcepts.api.helpers';
import * as errorUtils from 'shared/utils/errors';

import { StatusCodes } from 'http-status-codes';

export const useMetaConceptsApi = (): MetaConceptsApi => {
  const getMetaConcepts: MetaConceptsApi['getMetaConcepts'] = async params => {
    try {
      const response = await api.api_getMetaConcepts(params);

      return helpers.normalizeMetaConceptsResponse(response);
    } catch (e) {
      errorUtils.logError(e as Error);
      throw e;
    }
  };

  const addMetaConcept: MetaConceptsApi['addMetaConcept'] = async params => {
    try {
      const response = await api.api_postMetaConcepts(params);

      return helpers.normalizeMetaConcept(response);
    } catch (e) {
      const { status } = e as never;

      const isValidationError = status === StatusCodes.CONFLICT;

      // we used to call uilib showNotification method here , but now
      // we just rely on the caller that catches and handles the exception
      // in any case; this way avoid double notification

      errorUtils.logError(e as Error, 'Error', { isSilent: isValidationError });
      throw e;
    }
  };

  const modifyMetaConcept: MetaConceptsApi['modifyMetaConcept'] = async (id, params) => {
    try {
      const response = await api.api_putMetaConcepts(id, params);

      return helpers.normalizeMetaConcept(response);
    } catch (e) {
      const { status } = e as never;

      const isValidationError = status === StatusCodes.CONFLICT;

      // we used to call uilib showNotification method here , but now
      // we just rely on the caller that catches and handles the exception
      // in any case; this way avoid double notification

      errorUtils.logError(e as Error, 'Error', { isSilent: isValidationError });
      throw e;
    }
  };

  const deleteMetaConcept: MetaConceptsApi['deleteMetaConcept'] = async id => {
    try {
      return await api.api_deleteMetaConcepts(id);
    } catch (e) {
      errorUtils.logError(e as Error, 'Error');
      throw e;
    }
  };

  return {
    getMetaConcepts,
    addMetaConcept,
    modifyMetaConcept,
    deleteMetaConcept
  };
};
