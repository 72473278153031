import { createSelector } from 'reselect';
import { GlobalState } from '../../reducers';
import { REDUCER_NAME as SPIDER_RUN_REPORTS_REDUCER_NAME } from '../crawlerSettings/spiderRunReports/spiderRunReports.constants';
import { SpiderRunReportFromResponse } from './spiderRunReports.api.types';

export const getSpiderRunReportReducer = createSelector(
  (state: GlobalState) => state,
  (state: GlobalState) => state[SPIDER_RUN_REPORTS_REDUCER_NAME]
);

export const getSpiderRunReportItems = createSelector(
  getSpiderRunReportReducer,
  (currentSpiderRunReportReducer): SpiderRunReportFromResponse[] => {
    return currentSpiderRunReportReducer?.items ?? [];
  }
);

export const getFirstSpiderRunReport = createSelector(
  getSpiderRunReportItems,
  (currentSpiderRunReportItems): SpiderRunReportFromResponse => {
    return currentSpiderRunReportItems?.[0] ?? {};
  }
);
